import React from "react";
import styles from '../scss/Contacts.module.scss';
import Feedback from "../components/Feedback";

function Contacts() {

    return (

        <>


            <div className="wrapper">
                <div className="container">
                    <div className={styles.section}>
                        <div className={styles.text}>
                            <h4>Контакты</h4>
                            <p></p>
                        </div>
                        <div className={styles.contact}>
                            <div className={styles.item}>
                                <h4>Юридический адрес</h4>
                                <p>461050, Оренбургская область, город Бузулук, 3-й мкр, д. 13 а, кв. 11</p>

                                <h4>Офис региональных продаж</h4>
                                <p>PALAZZO, торгово-деловой центр, улица Пролетарская, д.247/2, 2 этаж, офис 5</p>

                                <h4>Сервисный центр (Обслуживание по гарантии)</h4>
                                <p>PALAZZO, торгово-деловой центр, улица Пролетарская, д.247/2, 2 этаж, офис 6</p>

                            </div>

                            <div className={styles.worktime}>
                                <div className={styles.item}>
                                    <p>Часы работы курьерской службы (г.Оренбург, местное время)</p>
                                    <ul>
                                        <li>Пн-Пт: с 9:00 до 17:30</li>
                                        <li>Сб-Вс: с 10:00 до 15:00</li>
                                    </ul>
                                    <p>Часы работы отдела контроля качества/регионального отдела продаж (По Московскому времени)</p>
                                    <ul>
                                        <li>Пн-Пт: с 9:00 до 19:00</li>
                                        <li>Сб-Вс: с 10:00 до 15:00</li>
                                    </ul>
                                </div>

                                <div className={styles.item}>
                                    <h4>Розничные магазины</h4>
                                    <p className={styles.item_p}>Шарлыкское шоссе, д.1, ТЦ "МАРМЕЛАД", 1 этаж, островок</p>
                                    <p className={styles.item_p}>Проспект Дзержинского, д.23, ТЦ "СЕВЕР", 2 этаж, островок</p>
                                </div>

                            </div>
                            <div className={styles.item_contact}>
                                <div className={styles.item}>
                                    <h4>+7 (901) 417-99-22</h4>
                                    <p className={styles.item_p}>Готовы ответить на интересующие вас вопросы по телефону
                                        и принять заказ.</p>
                                    <div className={styles.buttons}>
                                        <a href="tel:+79014179922" className={styles.button}><span>Позвонить</span></a>
                                        <a href="mailto:feedback.b2b@gmail.com" className={styles.button}><span>Отправить e-mail</span></a>
                                    </div>
                                </div>
                                <div className={styles.item}>
                                    <h4>feedback.b2b@gmail.com</h4>
                                    <p>По вопросам сотрудничества, идеям по улучшению и жалобам.</p>
                                    <div className={styles.buttons}>
                                        <a href="mailto:feedback.b2b@gmail.com" className={styles.button}><span>Отправить e-mail</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{background: "#fff"}}>
                <Feedback/>
            </div>
        </>


    );
}

export default Contacts;
