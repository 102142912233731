import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import CircularProgressWithLabel from './ProgressBar';

function ServiceQuiz() {
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('Или нажмите Enter');

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setHelperText(' ');
        setError(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (value === 'iphone' || value === 'ipad' || value === 'imac' || value === 'macbook' || value === 'other') {
            setHelperText('Выбрано ' + `${value}`);
            setError(false);
        } else {
            setHelperText('Выберите свое устройство');
            setError(true);
        }
    };

    const progress = 20;

    return (
        <div className="wrapper">
            <div className="container">
                <form onSubmit={handleSubmit}>
                    <FormControl sx={{m: 3}} error={error} variant="standard">

                        <FormLabel id="demo-error-radios" sx={{
                            color: "#000",
                            fontSize: "30px",
                            fontWeight: "500",
                            marginBottom: "15px",
                            '&.Mui-focused' : {
                                color: "#000",
                            }
                        }}>Какое у вас устройство?</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-error-radios"
                            name="quiz"
                            value={value}
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel value="iphone" control={
                                <Radio
                                    sx={{
                                        color: '#000',
                                        '&.Mui-checked': {
                                            color: '#000',
                                        },
                                    }}
                                />
                            } label="iPhone"/>
                            <FormControlLabel value="ipad" control={
                                <Radio
                                    sx={{
                                        color: '#000',
                                        '&.Mui-checked': {
                                            color: '#000',
                                        },
                                    }}
                                />
                            } label="iPad"/>

                            <FormControlLabel value="macbook" control={
                                <Radio
                                    sx={{
                                        color: '#000',
                                        '&.Mui-checked': {
                                            color: '#000',
                                        },
                                    }}
                                />
                            } label="MacBook"/>

                            <FormControlLabel value="imac" control={
                                <Radio
                                    sx={{
                                        color: '#000',
                                        '&.Mui-checked': {
                                            color: '#000',
                                        },
                                    }}
                                />
                            } label="iMac"/>

                            <FormControlLabel value="other" control={
                                <Radio
                                    sx={{
                                        color: '#000',
                                        '&.Mui-checked': {
                                            color: '#000',
                                        },
                                    }}
                                />
                            } label="Другое устройство"/>
                        </RadioGroup>
                        <FormHelperText>{helperText}</FormHelperText>
                        <Button sx={{
                            mt: 1, mr: 1, background: '#000', color: '#fff', '&:hover': {
                                background: '#000',
                            }
                        }} type="submit" variant="outlined">
                            Далее <CircularProgressWithLabel value={10}/>
                        </Button>

                    </FormControl>
                </form>
            </div>
        </div>
    );
}

export default ServiceQuiz;