import React from "react";
import styles from '../scss/ContactForm2.module.scss';
import axios from "axios";
import * as yup from "yup";
import {CSSTransition} from "react-transition-group";

function ContactForm() {

    const [sendText, setText] = React.useState('Отправить');


    const ref = React.useRef();

    const [formData, setFormData] = React.useState({
        name: '',
        phone: '',
        model: '',
        reason: '',
        isChecked: '',
        isChecked2: '',
    });
    const [isChecked, setIsChecked] = React.useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        formData.isChecked = !isChecked;
    };

    const [isChecked2, setIsChecked2] = React.useState(false);

    const handleCheckboxChange2 = () => {
        setIsChecked2(!isChecked2);
        formData.isChecked2 = !isChecked2;
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({...formData, [name]: value});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        validate();
        if (Object.keys(formErrors).length === 0) {
            axios.post('https://one-iphonestore.ru/api/send-form-service', formData)
                .then((response) => {
                    console.log(response);

                })
                .catch((error) => {
                    console.log(error);
                });

            setText('Отправка ...');
            setTimeout(() => {
                setText('Отправлено!');
                formData.name = '';
                formData.phone = '';
                formData.model = '';
                formData.reason = '';
                setIsChecked2(false);
                setIsChecked(false);
                formData.isChecked = 'false';
                formData.isChecked2 = 'false';

            }, 2000);

            setTimeout(() => {
                setText('Отправить');
            }, 10000);
        }
    }

    const validationSchema = yup.object().shape({
        name: yup.string().required('Обязательно *'),
        phone: yup.string().required('Обязательно *'),
        model: yup.string().required('Обязательно *'),
        reason: yup.string().required('Обязательно *'),
    });
    const [formErrors, setFormErrors] = React.useState({});

    const validate = () => {
        validationSchema.validate(formData, { abortEarly: false })
            .then(() => {
                setFormErrors({});
            })
            .catch((error) => {
                const errors = error.inner.reduce((acc, cur) => {
                    return { ...acc, [cur.path]: cur.message };
                }, {});
                setFormErrors(errors);
            });
    }



    return (

                <div className="container">
                    <div className={styles.section}>
                        <div className={styles.text}>
                            <div>
                                <h4>Оставить заявку</h4>
                                <p>Мы свяжемся с вами, ответим на все интересующие вас вопросы.</p>
                                <form className={styles.form} onSubmit={handleSubmit}>

                                    <div className={styles.form_row}>
                                        <div className={styles.form_group}>
                                            <label htmlFor="name">Ваше имя</label>
                                            <input type="text" name="name" className={styles.form_control} value={formData.name} onChange={handleInputChange} onBlur={validate}/>
                                            <CSSTransition in={formErrors.name != null} timeout={300} classNames="error-message" unmountOnExit>
                                                <div className="error-message">{formErrors.name}</div>
                                            </CSSTransition>
                                        </div>
                                        <div className={styles.form_group}>
                                            <label htmlFor="phone">Телефон *</label>
                                            <input type="text" name="phone" className={styles.form_control} value={formData.phone} onChange={handleInputChange} onBlur={validate}/>
                                            <CSSTransition in={formErrors.name != null} timeout={300} classNames="error-message" unmountOnExit>
                                                <div className="error-message">{formErrors.phone}</div>
                                            </CSSTransition>
                                        </div>
                                    </div>

                                    <div className={styles.form_row}>
                                        <div className={styles.form_group}>
                                            <label htmlFor="model">Какая у вас модель телефона?</label>
                                            <input type="text" name="model" className={styles.form_control} value={formData.model} onChange={handleInputChange} onBlur={validate}/>
                                            <CSSTransition in={formErrors.model != null} timeout={300} classNames="error-message" unmountOnExit>
                                                <div className="error-message">{formErrors.model}</div>
                                            </CSSTransition>
                                        </div>
                                        <div className={styles.form_group}>
                                            <label htmlFor="reason">Что именно сломалось? </label>
                                            <input type="text" name="reason" className={styles.form_control} value={formData.reason} onChange={handleInputChange} onBlur={validate}/>
                                            <CSSTransition in={formErrors.reason != null} timeout={300} classNames="error-message" unmountOnExit>
                                                <div className="error-message">{formErrors.reason}</div>
                                            </CSSTransition>
                                        </div>
                                    </div>


                                    <div className={styles.form_row}>
                                        <div className={styles.form_group}>
                                            <label htmlFor="garant1">Есть ли официальная гарантия на устройстве?</label>

                                            <div style={{display: "flex", alignItems: "center", gridGap: "10px"}}>
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    name="garant1"
                                                    onChange={handleCheckboxChange}
                                                    className={styles.checkbox}
                                                />
                                                {isChecked ? 'Да, у меня есть' : 'Нет :('}
                                            </div>


                                        </div>
                                        <div className={styles.form_group}>
                                            <label htmlFor="garant2">Есть ли официальная гарантия на устройстве?</label>
                                            <div style={{display: "flex", alignItems: "center", gridGap: "10px"}}>
                                                <input
                                                    type="checkbox"
                                                    name="garant2"
                                                    checked={isChecked2}
                                                    onChange={handleCheckboxChange2}
                                                    className={styles.checkbox}
                                                />
                                                {isChecked2 ? 'Да, у меня есть' : 'Нет :('}
                                            </div>


                                        </div>
                                    </div>



                                    <div className={styles.button}>
                                        <button type="submit" className={styles.send_button}>
                                            <span ref={ref}>{sendText} <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span>
                                        </button>
                                    </div>
                                </form>
                                <span>Нажимая на кнопку, вы даете согласие на обработку персональных данных</span>
                            </div>



                        </div>

                    </div>
                </div>

    );
}

export default ContactForm;
