import React from "react";
import * as yup from 'yup';
import styles from "../scss/Order.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {openEmailForm} from "../redux/slices/menuSlice";
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';

function FormEmail() {

    const ref = React.useRef();
    const isVisible = useSelector((state) => state.menu.emailform);
    const dispatch = useDispatch();

    const CloseModal = () => {
        ref.current.style.display = "none";
        dispatch(openEmailForm(!isVisible));
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "initial";
        }
    }, []);

    const refChooseContact = React.useRef('Перезвонить');
    const refDropdown = React.useRef(false);

    const [isContact, openContact] = React.useState(false);

    const handleClickContact = () => {
        openContact(!isContact);
    }

    const handleClickChoose = (e) => {
        refChooseContact.current.innerText = e.target.innerText;
        openContact(!isContact);
        //formData.method = refChooseContact.current.innerText;
        setFormData({...formData, method: refChooseContact.current.innerText})
    }

    const [formData, setFormData] = React.useState({
        name: '',
        phone: '',
        method: 'Перезвонить'
    });


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({...formData, [name]: value});
    }

    const [sendText, setText] = React.useState('Отправить');

    const handleSubmit = (event) => {
        event.preventDefault();
        validate();
        if (Object.keys(formErrors).length === 0) {
            axios.post('https://one-iphonestore.ru/api/send-email', formData)
                .then((response) => {
                    console.log(response);

                })
                .catch((error) => {
                    console.log(error);
                });

            setText('Отправка ...');
            setTimeout(() => {
                setText('Отправлено!');
                formData.name = '';
                formData.phone = '';
            }, 2000);

            setTimeout(() => {
                setText('Отправить');
            }, 10000);
        }
    }

    const validationSchema = yup.object().shape({
        name: yup.string().required('Обязательно для заполнения'),
        phone: yup.string().required('Обязательно для заполнения'),
    });
    const [formErrors, setFormErrors] = React.useState({});

    const validate = () => {
        validationSchema.validate(formData, { abortEarly: false })
            .then(() => {
                setFormErrors({});
            })
            .catch((error) => {
                const errors = error.inner.reduce((acc, cur) => {
                    return { ...acc, [cur.path]: cur.message };
                }, {});
                setFormErrors(errors);
            });
    }


    return (
        <div ref={ref} className={styles.popupFade} style={{position: "absolute", top: "0"}}>
            <div className={styles.popup}>
                <div className={styles.content} style={{maxWidth: "400px", borderRadius: "10px"}}>
                    <p onClick={() => CloseModal()} className={styles.closeCard}><svg viewBox="0 0 10 10"><path d="M5 4L1 0L0 1L4 5L0 9L1 10L5 6L9 10L10 9L6 5L10 1L9 0L5 4Z"></path></svg></p>
                    <div className={styles.data}>
                        <h5>Заполните все поля и мы оперативно свяжемся с вами. </h5>
                        <div className={styles.hr2}></div>
                        <form className={styles.form} onSubmit={handleSubmit}>
                            <div className={styles.form_group}>
                                <label htmlFor="name">ФИО *</label>
                                <input type="text" name="name" className={styles.form_control} value={formData.name} onChange={handleInputChange} onBlur={validate}/>
                                <CSSTransition in={formErrors.name != null} timeout={300} classNames="error-message" unmountOnExit>
                                    <div className="error-message">{formErrors.name}</div>
                                </CSSTransition>
                            </div>
                            <div className={styles.form_group}>
                                <label htmlFor="phone">Телефон *</label>
                                <input type="text" name="phone" className={styles.form_control} value={formData.phone} onChange={handleInputChange} onBlur={validate}/>
                                <CSSTransition in={formErrors.phone != null} timeout={300} classNames="error-message" unmountOnExit>
                                    <div className="error-message">{formErrors.phone}</div>
                                </CSSTransition>
                            </div>

                            <div className={styles.form_group}>
                                <div className={styles.form_contact}>
                                    <p>Как с вами связаться?</p>
                                    <div className={styles.choosecontact}>
                                        <div className={styles.input} onClick={() => handleClickContact()}>
                                      <span style={{display: isContact ? 'none': ''}}><strong ref={refChooseContact}>Перезвонить</strong><svg viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" d="M 1.4 6.99441e-15L 0 1.34043L 5 6L 10 1.34043L 8.6 0L 5 3.34998L 1.4 6.99441e-15Z"></path>
                                        </svg></span>

                                            {
                                                isContact && (
                                                    <div ref={refDropdown} className={styles.dropdown}>
                                                        <ul onClick={(e) => handleClickChoose(e)}>
                                                            <li>Перезвонить</li>
                                                            <li>Написать в WhatsApp</li>
                                                            <li>Написать в Telegram</li>
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>



                            <div className={styles.button}>
                                <button type="submit" className={styles.send_button} style={{width: "100%", justifyContent: "center"}}>
                                    <span>{sendText} <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span>
                                </button>
                            </div>

                            <div className={styles.policy}>
                                Нажимая на кнопку, вы даете согласие на обработку персональных данных
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default FormEmail