import React, { useState } from 'react';
import CreditCalc from "./CreditCalc";
import CreditCalc2 from "./CreditCalc2";
import styles from '../scss/Switch.module.scss';

const SwitchCalc = () => {
    const [activeComponent, setActiveComponent] = useState('one');

    const handleButtonClick = (component) => {
        setActiveComponent(component);
    };

    const renderComponent = () => {
        if (activeComponent === 'one') {
            return <CreditCalc />;
        } else if (activeComponent === 'two') {
            return <CreditCalc2 />;
        }
        return null;
    };

    return (
        <div className={styles.switch}>
            <div className={styles.switch_buttons}>
                <button
                    style={{
                    background: activeComponent === 'one' ? '#000' : '#fff',
                    color: activeComponent === 'one' ? '#fff' : '#000',
                    }}
                    onClick={() => handleButtonClick('one')}
                    className={styles.button}>
                    Калькулятор рассрочки
                </button>

                <button
                    style={{
                        background: activeComponent === 'two' ? '#000' : '#fff',
                        color: activeComponent === 'two' ? '#fff' : '#000',
                    }}
                    onClick={() => handleButtonClick('two')} className={styles.button}>Калькулятор кредита</button>
            </div>
            {renderComponent()}
        </div>
    );
};


export default SwitchCalc;