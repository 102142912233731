import { createSlice } from '@reduxjs/toolkit'
//import type { PayloadAction } from '@reduxjs/toolkit'


const initialState = {
    memory: false,
}

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        memory2: (state, action) => {
            state.value = true
        },
        close: (state, action) => {
            state.value = false
        },
        setMemory: (state, {payload}) => {
            state.memory = payload
        },
    },
})

export const { setMemory } = productSlice.actions

export default productSlice.reducer