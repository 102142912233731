import React from "react";
import styles from '../scss/Service.module.scss';
import Feedback from "../components/Feedback";
import image1 from "../assets/images/p1.png";
import image2 from "../assets/images/p2.png";
import image3 from "../assets/images/p3.png";
import ServiceQuiz from "../components/ServiceQuiz";
import ContactForm2 from "../components/ContactForm2";

function Service() {

    return (
        <>
            <div className="wrapper">
                <div className="container">
                    <div className={styles.section}>
                        <div className={styles.name}>
                            <h4>Сервисный центр техники Apple.</h4>
                            <div className={styles.desc}>
                                <h5>
                                    Собственная лаборатория. Первоклассные инженеры. Оригинальные и новые запчасти
                                    Apple.
                                </h5>
                                {/*<span>Нужна помощь? Задать вопрос <svg xmlns="http://www.w3.org/2000/svg" width="50"*/}
                                {/*                                       height="50" viewBox="-3 -3 22 22" id="icon"><path*/}
                                {/*    d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span>*/}

                            </div>
                        </div>
                        <ContactForm2/>

                        <div className={styles.service}>
                            <div className={styles.benefits}>
                                <div className={styles.item}>
                                    <h4>Удобно.</h4>
                                    <p>Собственная курьерская служба для забора техники в сервис и доставки обратно. Выездные инженеры.</p>
                                </div>
                                <div className={styles.item}>
                                    <h4>Надёжно.</h4>
                                    <p>В работе используем оригинальные запчасти. Даём гарантию на нашу работу и запчасти от 90 дней.</p>
                                </div>
                                <div className={styles.item}>
                                    <h4>Честно.</h4>
                                    <p>Только фиксированные цены — никаких накруток. Если что-то пойдёт не так, ремонт переделаем бесплатно.</p>
                                </div>
                            </div>
                            <div className={styles.start}>
                                <div className={styles.start_section}>
                                    <span>Работаем для вас <strong>с 2015 года.</strong></span>

                                    <div className={styles.items}>
                                        <img className={styles.image} src={image1}/>
                                        <img className={styles.image} src={image2}/>
                                        <img className={styles.image} src={image3}/>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/*<ServiceQuiz/>*/}
            <Feedback/>
        </>

    );
}

export default Service;