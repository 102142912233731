//import logo from './logo.svg';
//import './App.css';
import {Route, Routes} from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ProductRouter from "./pages/ProductRouter";
import Accessories from "./pages/Accessories";
import Contacts from "./pages/Contacts";
import Service from "./pages/Service";
import ScrollToTop from "./components/ScrollToTop";
import About from "./pages/About";
import Delivery from "./pages/Delivery";
import TaxFree from "./pages/TaxFree";
import Garant from "./pages/Garant";
import Credit from "./pages/Credit";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/Login";
import Reviews from "./pages/Reviews";
import Send from "./pages/Send";
import Test from "./pages/Test";

function App() {
  return (
    <div className="App">
        <Header/>
        <ScrollToTop>
            <Routes>
                <Route path="/" element={<Home/>}></Route>
                <Route path="/iphone" element={<ProductRouter/>}></Route>
                <Route path="/airpods" element={<ProductRouter/>}></Route>
                <Route path="/watch" element={<ProductRouter/>}></Route>
                <Route path="/dyson" element={<ProductRouter/>}></Route>
                <Route path="/ipad" element={<ProductRouter/>}></Route>
                <Route path="/mac" element={<ProductRouter/>}></Route>
                <Route path="/accessories" element={<Accessories/>}></Route>
                <Route path="/contacts" element={<Contacts/>}></Route>
                <Route path="/delivery" element={<Delivery/>}></Route>
                <Route path="/service" element={<Service/>}></Route>
                <Route path="/about" element={<About/>}></Route>
                <Route path="/taxfree" element={<TaxFree/>}></Route>
                <Route path="/garant" element={<Garant/>}></Route>
                <Route path="/credit" element={<Credit/>}></Route>
                <Route path="/reviews/:reviewId" element={<Reviews/>}></Route>
                <Route path="/dashboard" element={<Dashboard/>}></Route>
                <Route path="/login" element={<LoginPage/>}></Route>
                <Route path="/send" element={<Send/>}></Route>
                {/*<Route path="/test" element={<Test/>}></Route>*/}
            </Routes>
        </ScrollToTop>
        <Footer/>
    </div>
  );
}

export default App;
