import styles from '../scss/TaxFree.module.scss';
import React from "react";
import TaxFreeCalc from "../components/TaxFreeCalc";

function TaxFree() {
    return (
        <>
            <div className="wrapper">
                <div className="container">
                    <div className={styles.section}>
                        <div className={styles.head}>
                            <h4>TAX-FREECASH REFUND</h4>
                            <p>Приобретая товар в нашем магазине каждый клиент имеет возможность оформить TAX-Free Cash Refund. Давайте вместе разберёмся, что это такое.
                                Основную часть представленной к продаже продукции в нашем магазине мы приобретаем у партнёра-поставщика из Дубая. Исключением являются чехлы и бронестекла, которые мы получаем из Китая.
                                Согласно законодательству ОАЭ, если товар был куплен на территории страны и в последующем был экспортирован, то покупатель товара имеет право на возврат TAX-FREE (Аналог Российскому НДС)
                                Что всё это означает? Любой наш клиент, как иностранный покупатель продукции, может воспользоваться услугой возврата TAX-FREE CASH REFUND, в ОАЭ размер возврата на мобильные телефоны относится к повышенной категории и составляет 22% от стоимости продукции. Весь процесс по подаче заявления в TAX-REFUND пункт наш магазин берёт на себя, от клиента необходимо заполнить только одну электронную форму на сайте оператора TAX-Free.
                                Внимание, такс фри доступен только для заказных товаров из ОАЭ. Это могут быть как новые, так и Б.У гаджеты. На технику, которая находится в наличии в нашем магазине, такс фри опция недоступна.
                                 </p>

                                <ul>
                                    <li>Максимальный срок возврата составляет 7 дней.</li>
                                    <li>Для осуществления покупки с опцией такс фри требуется полная предоплата товара.</li>
                                </ul>
                        </div>

                        <TaxFreeCalc/>

                        <div className={styles.data}>
                            <h4>Каким образом оформляется TAX-Free:</h4>
                            <ul>
                                <li>Личный кабинет оператора TAX-Free Global Blue загружаем уникальный номер-идентификатор нашего поставщика из ОАЭ</li>
                                <li>Загружаем квитанцию об оплате</li>
                                <li>Предоставляем сумму покупки в Дирхамах</li>
                                <li>Указываем нашу зарубежную карту для возврата (А мы переводим вам сумму возврата рублями по актуальному курсу)</li>
                            </ul>
                            <p>Заявление составляется и подаётся к электронном виде</p>
                        </div>

                        <div className={styles.order}>
                            <h4>Способы оплаты товара через TAX-FREE</h4>
                            <p>Оформление покупки по системе TAX-Free Cash Refund требует внесение полной стоимости товара до отправления. Данное условие связано с необходимостью предоставления в TAX-REFUND пункт товарного и кассового чеков, дата которых совпадает с датой отправления товара.</p>
                            <div className={styles.items}>
                                <div className={styles.item}>
                                    <h4>ВОЗВРАТ 22% ОТ СТОИМОСТИ ТОВАРА</h4>
                                    <ul>
                                        <li>Клиент производит полную оплату за товар.  </li>
                                        <li>Клиент заполняет электронную форму возврата, наш магазин подаёт заявление на возврат TAX-Free. </li>
                                        <li>22% от стоимости товара поступают на карту, указанную клиентом. </li>
                                        <li>Срок осуществления TAX-Возврата составляет до 7 Календарных дней. </li>
                                    </ul>
                                </div>
                                <div className={styles.item}>
                                    <h4>СКИДКА 15% ПРИ ПОКУПКЕ ТОВАРА</h4>
                                    <ul>
                                        <li>Клиент производит оплату за товар с учётом скидки 15%.</li>
                                        <li>Весь процесс оформления TAX-Free Cash Refund наш магазин осуществляет сам, клиенту не нужно ничего заполнять. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={styles.faq}>
                            <h4>Ответы на вопросы</h4>

                            <div className={styles.service}>

                                <div className={styles.benefits}>
                                    <div className={styles.item}>
                                        <h4>Могу ли я оформить TAX-Free на товар, который есть в наличии в вашем магазине?</h4>
                                        <p>TAX-Free Cash Refund не действует на товар, который находится в наличии. TAX-Free оформляется только на тот товар, который мы ввозим транзитом из Дубая. Но у нас в офисе вы можете лично оформить заказ с доставкой по TAX-Free. Доставка от поставщика к нам в офис занимает 4 дня, либо же можем сразу оформить заказ напрямую от поставщика по вашему адресу. Во всех этих случаях вы сможете оформить TAX-Free. </p>
                                    </div>
                                    <div className={styles.item}>
                                        <h4>Могу ли я оформить TAX-Free от юр. лица?</h4>
                                        <p>Оформление TAX-Free доступно только для покупок физ. лицами. Для юридических лиц мы можем предложить специальные программы лояльности (См. раздел "Партнёрство/ОПТ). </p>
                                    </div>

                                    <div className={styles.item}>
                                        <h4>TAX-FREE и Кредит</h4>
                                        <p>При покупке товара в кредит оформить на него TAX-Free нельзя, так как договор оформляется между покупателем и банком. Альфа Банк является юридическим лицом, что делает осуществление возврата невозможным. </p>
                                    </div>
                                </div>
                                <div className={styles.benefits}>
                                    <div className={styles.item}>
                                        <h4>Может ли оформить TAX-Free гражданин СНГ?</h4>
                                        <p>Да, вы можете оформить возврат, если вы являетесь гражданином СНГ (Азербайджан, Армения, Белоруссия, Казахстан, Киргизия, Молдавия, Таджикистан, Узбекистан.) </p>
                                    </div>
                                    <div className={styles.item}>
                                        <h4>Б/У Продукция и TAX-Free</h4>
                                        <p>В нашем магазине возможно оформление TAX-Free в том числе и на Б/У товар. Исключением являются случаи, когда Б/У товар появился путем сдачи в Trade-In техники от другого клиента, а не от поставщика. (Вы сразу поймете, возможно ли оформить TAX-Free на интересующую вас модель: просто внимательно читайте описание). </p>
                                    </div>
                                    <div className={styles.item}>
                                        <h4>Бывают ли случаи отказа в выплате TAX-Free?</h4>
                                        <p>За всё время работы нашего магазина не было ни единого случая отказа в выплате. Даже если клиент допустит грубую ошибку в заполнении электронной формы и по каким-либо причинам бухгалтерия магазина эту ошибку не увидит (один случай на миллион), то TAX-FREE пункт просто попросит составить запрос заново, исправив все ошибки. </p>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TaxFree