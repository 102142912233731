export const data =
[
  {
    "id": 1,
    "product": "iphone",
    "name": "iPhone 14 Pro Max",
    "desc": "Версия со слотом под физическую сим-карту.",
    "price": 84900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#6b557e",
          "title": "Темно-фиолетовый",
          images: [require("./assets/images/Products/35921550_1920_q55.avif")]
        },
        {
          "id": 2,
          "color": "#333",
          "title": "Чёрный космос",
          images: [require("./assets/images/Products/35921555_1920_q55.avif")]
        },
        {
          "id": 3,
          "color": "#f9ead3",
          "title": "Золото",
          images: [require("./assets/images/Products/35921567_1920_q55.avif")]
        },
        {
          "id": 4,
          "color": "#f2f3ed",
          "title": "Серебристый",
          images: [require("./assets/images/Products/35921561_1920_q55.avif")]
        }
      ],
      memory: [
        {"id": 1, "value": "128 ГБ", price: 0},
        {"id": 2, "value": "256 ГБ", price: 5000},
        {"id": 3, "value": "512 ГБ", price: 22000},
        {"id": 4, "value": "1 ТБ", price: 30000},
      ],
    }],
  },
  {
    "id": 2,
    "product": "iphone",
    "name": "iPhone 14 Pro",
    "desc": "Версия со слотом под физическую сим-карту.",
    "price": 71400,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#6b557e",
          "title": "Темно-фиолетовый",
          images: [require("./assets/images/Products/26406530_359_q55.avif")]
        },
        {
          "id": 2,
          "color": "#333",
          "title": "Чёрный космос",
          images: [require("./assets/images/Products/26406534_359_q55.avif")]
        },
        {
          "id": 3,
          "color": "#f9ead3",
          "title": "Золото",
          images: [require("./assets/images/Products/26406537_359_q55.avif")]
        },
        {
          "id": 4,
          "color": "#f2f3ed",
          "title": "Серебристый",
          images: [require("./assets/images/Products/26406540_359_q55.avif")]
        }
      ],
      memory: [
        {"id": 1, "value": "128 ГБ", price: 0},
        {"id": 2, "value": "256 ГБ", price: 5000},
        {"id": 3, "value": "512 ГБ", price: 9200},
        {"id": 4, "value": "1 ТБ", price: 21100},
      ],
    }],
  },
  {
    "id": 3,
    "product": "iphone",
    "name": "iPhone 14",
    "desc": "Версия со слотом под физическую сим-карту.",
    "price": 49300,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#c1cedc",
          "title": "Голубой",
          images: [require("./assets/images/Products/14.webp")]
        },
        {
          "id": 2,
          "color": "#e21c2a",
          "title": "Красный",
          images: [require("./assets/images/Products/141.webp")]
        },
        {
          "id": 3,
          "color": "#ead7e6",
          "title": "Фиолетовый",
          images: [require("./assets/images/Products/142.webp")]
        },
        {
          "id": 4,
          "color": "#333438",
          "title": "Темная ночь",
          images: [require("./assets/images/Products/143.webp")]
        },
        {
          "id": 5,
          "color": "#f0ebea",
          "title": "Сияющая звезда",
          images: [require("./assets/images/Products/144.webp")]
        },
        {
          "id": 6,
          "color": "#f5e488",
          "title": "Желтый",
          images: [require("./assets/images/Products/145.webp")]
        }
      ],
      memory: [
        {"id": 1, "value": "128 ГБ", price: 0},
        {"id": 2, "value": "256 ГБ", price: 4600},
        {"id": 3, "value": "512 ГБ", price: 8600},
      ],
    }],
  },
  {
    "id": 4,
    "product": "airpods",
    "name": "AirPods Pro",
    "desc": "Активное шумоподавление. Прозрачный режим. Новый дизайн и новый уровень комфорта. Потрясающее качество звука.",
    "price": 12990,
    "count": 0,
    options: [{
      color: "disabled",
      images: [
          require("./assets/images/Products/33997040_1920_q55.avif"),
          require("./assets/images/Products/33997041_1920_q55.avif"),
          require("./assets/images/Products/33996989_1920_q55.avif"),
          require("./assets/images/Products/33996988_1920_q55.avif"),
      ],

      generation: [
        {"id": 1, "value": "1 gen", price: 0, code: "MVP22"},
        {"id": 2, "value": "2 gen", price: 5000, code: "MQD83"},
      ],

    }],
  },
  {
    "id": 5,
    "product": "airpods",
    "name": "AirPods 2 беспроводная зарядка",
    "desc": "Увеличенное время работы в режиме разговора, активация Siri с помощью голоса. AirPods — совершенно уникальные беспроводные наушники. Они легко подключаются к вашим устройствам, моментально готовы к работе и великолепно звучат. Просто волшебно.",
    "price": 8990,
    "count": 0,
    "code": "MV7N2",
    options: [{
      color: "disabled",
      images: [
        require("./assets/images/Products/33997091_1920_q55.avif"),
        require("./assets/images/Products/33997089_1920_q55.avif"),
        require("./assets/images/Products/33997088_1920_q55.avif"),
      ],

    }],
  },
  {
    "id": 6,
    "product": "watch",
    "name": "Watch Ultra Alpine Loop",
    "desc": "Для любителей активного отдыха. Удобный и чрезвычайный прочный ремешок. G-образный крючок обеспечивает надежную посадку.",
    "price": 69900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#f06a21",
          "title": "Organe",
          "code": "MQF03",
          images: [
              require("./assets/images/Products/33997090_1920_q55.avif"),
              require("./assets/images/Products/33996380_1920_q55.avif"),
              require("./assets/images/Products/33996378_1920_q55.avif"),
          ]
        },
        {
          "id": 2,
          "color": "#5a5e53",
          "title": "Green",
          "code": "MQEW3",
          images: [
              require("./assets/images/Products/33996385_1920_q55.avif"),
              require("./assets/images/Products/33996387_1920_q55.avif"),
              require("./assets/images/Products/33996384_1920_q55.avif"),
          ]
        },
        {
          "id": 3,
          "color": "#e7dbd3",
          "title": "Starlight",
          "code": "MQF03",
          images: [
              require("./assets/images/Products/33996392_1920_q55.avif"),
              require("./assets/images/Products/33996393_1920_q55.avif"),
              require("./assets/images/Products/33996391_1920_q55.avif"),

          ]
        }
      ],

      size: [
        {"id": 1, "value": "49 ММ", price: 0},
      ],
    }],
  },
  {
    "id": 7,
    "product": "ipad",
    "name": "iPad Pro 12.9″ M2 (2022)",
    "desc": "",
    "price": 89900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#5c5b56",
          "title": "Серый космос",
          images: [
            require("./assets/images/Products/30039452_1920_q55.avif"),
          ]
        },
        {
          "id": 2,
          "color": "#eeeff1",
          "title": "Серебристый",
          images: [
            require("./assets/images/Products/30039453_1920_q55.avif"),
          ]
        },
      ],
      memory: [
        {"id": 1, "value": "128 ГБ", price: 0},
        {"id": 2, "value": "256 ГБ", price: 4000},
        {"id": 3, "value": "512 ГБ", price: 16000},
        {"id": 4, "value": "1 ТБ", price: 36000},
      ],
      net: [
        {"id": 1, "value": "Wi-Fi", price: 0},
        {"id": 2, "value": "Wi-Fi + LTE", price: 10000},
      ],
    }],
  },
  {
    "id": 8,
    "product": "mac",
    "name": "MacBook Pro 16\" M2 Pro (2023)",
    "desc": "Лазерная гравировка русских букв — бесплатно. Кастомные конфигурации по запросу.",
    "price": 179900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#5c5b56",
          "title": "Серый космос",
          images: [
            require("./assets/images/Products/eqy9ccd02say8zs1nlplj94kc1oz7jik.webp"),
          ]
        },
        {
          "id": 2,
          "color": "#eeeff1",
          "title": "Серебристый",
          images: [
            require("./assets/images/Products/1td0g14h5r28ivhwnmfu33ieh6bylr0u.webp"),
          ]
        },
      ],
      memory: [
        {"id": 1, "value": "512 ГБ", price: 0},
        {"id": 2, "value": "1 ТБ", price: 20000},
        {"id": 3, "value": "2 ТБ", price: 35000},
      ],
      ram: [
        {"id": 1, "value": "16 ГБ", price: 0},
        {"id": 2, "value": "32 ГБ", price: 0, sell: "false"},
      ],
    }],
  },
  {
    "id": 9,
    "product": "accessories",
    "name": "Sony PS5",
    "desc": "Лазерная гравировка русских букв — бесплатно. Кастомные конфигурации по запросу.",
    "price": 30,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#5c5b56",
          "title": "Серый космос",
          images: [
            require("./assets/images/Products/30039452_1920_q55.avif"),
          ]
        },
        {
          "id": 2,
          "color": "#eeeff1",
          "title": "Серебристый",
          images: [
            require("./assets/images/Products/30039453_1920_q55.avif"),
          ]
        },
      ],
      memory: [
        {"id": 1, "value": "512 ГБ", price: 0},
        {"id": 2, "value": "1 ТБ", price: 20},
        {"id": 3, "value": "2 ТБ", price: 30},
      ],
      ram: [
        {"id": 1, "value": "16 ГБ", price: 0},
        {"id": 2, "value": "32 ГБ", price: 20},
      ],
    }],
  },
  {
    "id": 10,
    "product": "airpods",
    "name": "AirPods 3",
    "desc": "Увеличенное время работы в режиме разговора, активация Siri с помощью голоса. AirPods — совершенно уникальные беспроводные наушники. Они легко подключаются к вашим устройствам, моментально готовы к работе и великолепно звучат. Просто волшебно.",
    "price": 16990,
    "count": 0,
    "code": "MV7N3",
    options: [{
      color: "disabled",
      images: [
        require("./assets/images/Products/33997091_1920_q55.avif"),
        require("./assets/images/Products/33997089_1920_q55.avif"),
        require("./assets/images/Products/33997088_1920_q55.avif"),
      ],

    }],
  },
  {
    "id": 11,
    "product": "iphone",
    "name": "iPhone 13",
    "desc": "",
    "price": 41600,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#fce1dd",
          "title": "Розовый",
          images: [require("./assets/images/Products/e6yhoweed3jsewbtz2hi0uuywi103btg.png"), require("./assets/images/Products/zl4gu9qyx3pndfbth6d5qt87suq11mwn.png"), require("./assets/images/Products/8cku4od8yczrpz44kwtzyl7h6zjx47kx.png")]
        },
        {
          "id": 2,
          "color": "#e21c2a",
          "title": "Красный",
          images: [require("./assets/images/Products/yvgmg8yxomfrsogusva6ful0voqwwsyn.png"), require("./assets/images/Products/xsbba8ezuvv6yc83mcmmzeq78zn6dzyt.png"), require("./assets/images/Products/u59g6b861rcbhsyem026msc2485258sf.png")]
        },
        {
          "id": 3,
          "color": "#333438",
          "title": "Темная ночь",
          images: [require("./assets/images/Products/eh02d05mxicbedkshztk8ps1yz8lisf6.png"), require("./assets/images/Products/ixmr6fahy4xqotn39030p8ekzg8gwtjo.png"), require("./assets/images/Products/ke0ix71kceibl1kmyffn86za6c4a3k0c.png")]
        },
        {
          "id": 4,
          "color": "#f0ebea",
          "title": "Сияющая звезда",
          images: [require("./assets/images/Products/fqvat5zd01qp2pnj3jc6pmb0szzu3jod.png"), require("./assets/images/Products/1nu14ehy8mbi4lpe0r3q2ucfgivfik07.png"), require("./assets/images/Products/o8qtq6fjqzc606f0nywt29kzsl3kj88k.png")]
        },
        {
          "id": 5,
          "color": "#42778e",
          "title": "Синий",
          images: [require("./assets/images/Products/i02xak4yiypn3tb1bxdn26vsy24b2685.png"), require("./assets/images/Products/6t4dfgbv2jhdlp293z6bm30rg3dsqphl.png"), require("./assets/images/Products/35z8wnxse73bwlpe70isrghkg28469qm.png")]
        },
        {
          "id": 6,
          "color": "#445647",
          "title": "Зеленый",
          images: [require("./assets/images/Products/iy0vhi9binga3uh054j9v5jhgqyybm60.png"), require("./assets/images/Products/dhbtr8z8722qipvwj5ovtdw1eybxra8x.png"), require("./assets/images/Products/g08ip818oz6lgnmvml8yv2tl9nzk801i.png")]
        }
      ],
      memory: [
        {"id": 1, "value": "128 ГБ", price: 0},
        {"id": 2, "value": "256 ГБ", price: 6700},
        {"id": 3, "value": "512 ГБ", price: 10300},
      ],
    }],
  },
  {
    "id": 12,
    "product": "iphone",
    "name": "iPhone 13 Pro",
    "desc": "",
    "price": 53500,
    "count": 0,
    options: [{

      color: [
        {
          "id": 1,
          "color": "#f9ead3",
          "title": "Золотой",
          images: [require("./assets/images/Products/13mgold.png")]
        },
        {
          "id": 2,
          "color": "#f2f3ed",
          "title": "Серебристый",
          images: [require("./assets/images/Products/13mser.png")]
        },
        {
          "id": 3,
          "color": "#333438",
          "title": "Графитовый",
          images: [require("./assets/images/Products/13mblack.png")]
        },
        {
          "id": 4,
          "color": "#42778e",
          "title": "Небесно-голубой",
          images: [require("./assets/images/Products/13mblue.png")]
        },
      ],
      memory: [
        {"id": 1, "value": "128 ГБ", price: 0},
        {"id": 2, "value": "256 ГБ", price: 5800},
        {"id": 3, "value": "512 ГБ", price: 12100},
        {"id": 4, "value": "1 ТБ", price: 18400},
      ],
    }],
  },
  {
    "id": 13,
    "product": "iphone",
    "name": "iPhone 13 Pro Max",
    "desc": "",
    "price": 59900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#f9ead3",
          "title": "Золотой",
          images: [require("./assets/images/Products/13g.png")]
        },
        {
          "id": 2,
          "color": "#f2f3ed",
          "title": "Серебристый",
          images: [require("./assets/images/Products/13blue.png")]
        },
        {
          "id": 3,
          "color": "#333438",
          "title": "Графитовый",
          images: [require("./assets/images/Products/13black.png")]
        },
        {
          "id": 4,
          "color": "#42778e",
          "title": "Небесно-голубой",
          images: [require("./assets/images/Products/13b.png")]
        },
        {
          "id": 5,
          "color": "#649862",
          "title": "Альпийский-зеленый",
          images: [require("./assets/images/Products/13green.png")]
        },
      ],
      memory: [
        {"id": 1, "value": "128 ГБ", price: 0},
        {"id": 2, "value": "256 ГБ", price: 6300},
        {"id": 3, "value": "512 ГБ", price: 10000},
        {"id": 4, "value": "1 ТБ", price: 20000},
      ],
    }],
  },
  {
    "id": 14,
    "product": "watch",
    "name": "Apple Watch Series 8",
    "desc": "Для любителей активного отдыха. Удобный и чрезвычайный прочный ремешок. G-образный крючок обеспечивает надежную посадку.",
    "price": 28400,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#e21c2a",
          "title": "Красный",
          "code": "MQF05",
          images: [
            require("./assets/images/Products/qyldhhcpw9urg8b4xc0c5ukqfgy6atha.webp"),
            require("./assets/images/Products/y6gef94f0e7k5jo6n5o3yvgxsykclkcf.webp"),
            require("./assets/images/Products/ztnb1hiswwj23ewcyq4o7070p9tctlfi.webp"),
          ]
        },
        {
          "id": 2,
          "color": "#333438",
          "title": "Темная ночь",
          "code": "MQEW1",
          images: [
            require("./assets/images/Products/ynn12jh2xaj740oflpko4624ze1n433k.webp"),
            require("./assets/images/Products/blojxzd334zjvqzvwhdtlz7qngck6srz.webp"),
            require("./assets/images/Products/cc5t1i4q4cfl14npz1snezeva94pi9rl.webp"),
          ]
        },
        {
          "id": 3,
          "color": "#f0ebea",
          "title": "Сияющая звезда",
          "code": "MQF23",
          images: [
            require("./assets/images/Products/yb2btrd4107e0msz0dtwq30kxfzz4mh4.webp"),
            require("./assets/images/Products/3jw4990scqc1t1s2h30sq1zu3ta9gqlq.webp"),
            require("./assets/images/Products/glo2gkjgr28ozzf420itif1ppuzoa92q.webp"),

          ]
        },
        {
          "id": 4,
          "color": "#f7f7f7",
          "title": "Белый",
          "code": "MQF23",
          images: [
            require("./assets/images/Products/zqd336b8wfquyb0m3dimcip7xrtjr2pe.webp"),
            require("./assets/images/Products/xjqi7159zdp3g4bg9xs0km30a5g0516b.webp"),
            require("./assets/images/Products/h5ch1bfr8hlwfixvrwl7pqtfs7veqnyq.webp"),

          ]
        }
      ],

      size: [
        {"id": 1, "value": "41 ММ", price: 0},
        {"id": 2, "value": "45 ММ", price: 3300},
      ],
    }],
  },
  {
    "id": 15,
    "product": "ipad",
    "name": "Apple iPad 10.9″ (2022)",
    "desc": "",
    "price": 49900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#f5e488",
          "title": "Желтый",
          images: [
            require("./assets/images/Products/w91teqtjcqvortqxqsitwymh90yunpc6.webp"),
          ]
        },
        {
          "id": 2,
          "color": "#42778e",
          "title": "Синий",
          images: [
            require("./assets/images/Products/321hpa07nblxk2n1g1pw35ql7u11otp1.webp"),
          ]
        },
        {
          "id": 3,
          "color": "#ffbfcc",
          "title": "Розовый",
          images: [
            require("./assets/images/Products/m7qc5jpgubmpc5hx4ifpenheaicbvl1v.webp"),
          ]
        },
        {
          "id": 4,
          "color": "#f1f1f1",
          "title": "Серебристый",
          images: [
            require("./assets/images/Products/6skird92a1vj1c9u6p4hdw9xf1ljlxpt.webp"),
          ]
        },
      ],
      memory: [
        {"id": 1, "value": "64 ГБ", price: 0},
        {"id": 2, "value": "256 ГБ", price: 4000},

      ],
      net: [
        {"id": 1, "value": "Wi-Fi", price: 0},
        {"id": 2, "value": "Wi-Fi + LTE", price: 10000},
      ],
    }],
  },
  {
    "id": 16,
    "product": "mac",
    "name": "Apple MacBook Pro 13\" M2 (2022)",
    "desc": "Лазерная гравировка русских букв — бесплатно. Кастомные конфигурации по запросу.",
    "price": 116900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#5c5b56",
          "title": "Серый космос",
          images: [
            require("./assets/images/Products/qn91e3j0e9vwpiuyggqzvxgaf4oe6wg8.png"),
          ]
        },
        {
          "id": 2,
          "color": "#eeeff1",
          "title": "Серебристый",
          images: [
            require("./assets/images/Products/kvzmg25lz2kzj33fcdy5qaf3eymgo1p6.png"),
          ]
        },
      ],
      memory: [
        {"id": 1, "value": "256 ГБ", price: 0},
        {"id": 2, "value": "512 ГБ", price: 20000},
      ],
      ram: [
        {"id": 1, "value": "8 ГБ", price: 0},
      ],
    }],
  },
  {
    "id": 17,
    "product": "watch",
    "name": "Apple Watch Series 7",
    "desc": "Для любителей активного отдыха. Удобный и чрезвычайный прочный ремешок. G-образный крючок обеспечивает надежную посадку.",
    "price": 24400,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#e21c2a",
          "title": "Красный",
          "code": "MQF05",
          images: [
            require("./assets/images/Products/wr2.webp"),
            require("./assets/images/Products/wr.webp"),
          ]
        },
        {
          "id": 2,
          "color": "#4c634e",
          "title": "Зеленый клевер",
          "code": "MQEW1",
          images: [
            require("./assets/images/Products/wg2.webp"),
            require("./assets/images/Products/wg.webp"),
          ]
        },
        {
          "id": 3,
          "color": "#334457",
          "title": "Синий омут",
          "code": "MQF23",
          images: [
            require("./assets/images/Products/wb2.webp"),
            require("./assets/images/Products/wb.webp"),
          ]
        },
        {
          "id": 4,
          "color": "#d1c7bf",
          "title": "Сияющая звезда",
          "code": "MQF23",
          images: [
            require("./assets/images/Products/ww2.webp"),
            require("./assets/images/Products/ww.webp"),
          ]
        }
      ],

      size: [
        {"id": 1, "value": "41 ММ", price: 0},
        {"id": 2, "value": "45 ММ", price: 2300},
      ],
    }],
  },
  {
    "id": 18,
    "product": "watch",
    "name": "Watch Ultra 2 49MM GPS",
    "desc": "Смарт-часы Apple Watch Ultra GPS с титановым корпусом созданы для самых увлеченных спортсменов. Яркий дисплей, прочный корпус, продуманное управление и специальные спортивные ремешки: все для тех, кто не привык останавливаться на достигнутом.",
    "price": 79900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#938781",
          "title": "Серо-голубой",
          "code": "",
          images: [
            require("./assets/images/Products/wu/1/2.jpeg"),
            require("./assets/images/Products/wu/1/1.jpeg"),
            require("./assets/images/Products/wu/1/3.jpeg"),
          ]
        },
        {
          "id": 2,
          "color": "#373737",
          "title": "Серый",
          "code": "",
          images: [
            require("./assets/images/Products/wu/titan/2.jpeg"),
            require("./assets/images/Products/wu/titan/1.png"),
            require("./assets/images/Products/wu/titan/3.jpeg"),
          ]
        },
        {
          "id": 3,
          "color": "#3b3c32",
          "title": "Зеленый",
          "code": "MQF23",
          images: [
            require("./assets/images/Products/wu/green/1.jpeg"),
            require("./assets/images/Products/wu/green/2.png"),

          ]
        },
        {
          "id": 4,
          "color": "#bd3717",
          "title": "Альпийский оранжевый",
          "code": "",
          images: [
            require("./assets/images/Products/wu/org/1.jpeg"),
            require("./assets/images/Products/wu/org/3.png"),
            require("./assets/images/Products/wu/org/2.jpeg"),
          ]
        },
        {
          "id": 5,
          "color": "#f0ebea",
          "title": "Сияющая звезда",
          "code": "",
          images: [
            require("./assets/images/Products/wu/4/1.jpeg"),
            require("./assets/images/Products/wu/4/2.png"),
            require("./assets/images/Products/wu/4/3.jpeg"),
          ]
        },
        {
          "id": 6,
          "color": "#2b2e35",
          "title": "Морской черный",
          "code": "",
          images: [
            require("./assets/images/Products/wu/5/1.jpeg"),
            require("./assets/images/Products/wu/5/2.png"),
            require("./assets/images/Products/wu/5/3.jpeg"),
          ]
        }
      ],

      size: [
        {"id": 1, "value": "49 ММ", price: 0},
      ],
    }],
  },
  {
    "id": 19,
    "product": "watch",
    "name": "Apple Watch Series 9",
    "desc": "Apple Watch Series 9 представляют собой уникальное сочетание элегантного привычного дизайна и новейших технологий, делая эту модель идеальным аксессуаром для каждого дня. ",
    "price": 35200,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#ffc0cb",
          "title": "Нежно-розовый",
          "code": "",
          images: [
            require("./assets/images/Products/9/pink/sepfhx0sr1gp50rnn90ukxhjkfowp8zf.jpg"),
            require("./assets/images/Products/9/pink/5p3xjp86si0uti26cycqylggtez8srdx.jpg"),
            require("./assets/images/Products/9/pink/m2cgh92isvcg1s8j9u8rh7qg2b5elrfc.jpg"),
          ]
        },
        {
          "id": 2,
          "color": "#333438",
          "title": "Темная ночь",
          "code": "",
          images: [
            require("./assets/images/Products/9/dark/srl4ngql11yfxis9zrcb35lyciam1d7b.jpg"),
            require("./assets/images/Products/9/dark/1yf1md0hnza97pgsoow0ip5brre0lwd4.jpg"),
            require("./assets/images/Products/9/dark/p0j74p2u6ygsexd98kmribaior2jc2ss.jpg"),
          ]
        },
        {
          "id": 3,
          "color": "#f0ebea",
          "title": "Сияющая звезда",
          "code": "",
          images: [
            require("./assets/images/Products/9/star/46iyza19vss8gi02n3syheig6bmsorc0.jpg"),
            require("./assets/images/Products/9/star/jbs9j9550knzu3w541qxvbtkjwwuvdk2.jpg"),
            require("./assets/images/Products/9/star/g9dnl9t4xvuxwts7odpatlge68jenoij.jpg"),
          ]
        },
        {
          "id": 4,
          "color": "#28365a",
          "title": "Ледяной синий",
          "code": "",
          images: [
            require("./assets/images/Products/9/cold/kvzoe1ev2pudnain0tf31qw8y27kgqvq.jpg"),
            require("./assets/images/Products/9/cold/conymevpggf5z1hfltykbtlrwbg90fs0.jpg"),
            require("./assets/images/Products/9/cold/svxm3gk4jvtuxw9nv0ztuoyfmyua7vk0.jpg"),
          ]
        },
      ],

      size: [
        {"id": 1, "value": "41 ММ", price: 0},
        {"id": 2, "value": "45 ММ", price: 3300},
      ],
    }],
  },
  {
    "id": 20,
    "product": "iphone",
    "name": "iPhone 15",
    "desc": "iPhone 15 представляет собой следующий этап в эволюции смартфонов Apple. Его стеклянный корпус с матовой отделкой и алюминиевая рамка сочетают в себе элегантность и прочность, делая его одним из самых красивых и устойчивых к повреждениям телефонов на рынке. ",
    "price": 61900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#f5ea8f",
          "title": "Желтый",
          images: [require("./assets/images/Products/iphone15/97zt3l8j2ilgn5tuq4u8z8qlaxmcizju.jpg.png")]
        },
        {
          "id": 2,
          "color": "#afd9c6",
          "title": "Зеленый",
          images: [require("./assets/images/Products/iphone15/ndwr4q06zzv37710kiuyt06ybq6w273e.jpg.png")]
        },
        {
          "id": 3,
          "color": "#ffc0cb",
          "title": "Розовый",
          images: [require("./assets/images/Products/iphone15/im73qoyp54vaqmut3ampm35sq0o6i663.jpg.png")]
        },
        {
          "id": 4,
          "color": "#436397",
          "title": "Синий",
          images: [require("./assets/images/Products/iphone15/z9wf8x2tdjia0xfwg88e89njq5y71vtk.png")]
        },
        {
          "id": 5,
          "color": "#3a3a3a",
          "title": "Черный",
          images: [require("./assets/images/Products/iphone15/ijnb2hvv2sovmg0cgbrvkkpskbj4mqt0.jpg.png")]
        }
      ],
      memory: [
        {"id": 1, "value": "128 ГБ", price: 0},
        {"id": 2, "value": "256 ГБ", price: 7090},
        {"id": 3, "value": "512 ГБ", price: 15090},
      ],
    }],
  },
  {
    "id": 21,
    "product": "iphone",
    "name": "iPhone 15 Plus",
    "desc": "iPhone 15 Plus представляет собой следующий этап в эволюции смартфонов Apple. Его стеклянный корпус с матовой отделкой и алюминиевая рамка сочетают в себе элегантность и прочность, делая его одним из самых красивых и устойчивых к повреждениям телефонов на рынке.",
    "price": 69900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#f5ea8f",
          "title": "Желтый",
          images: [require("./assets/images/Products/iphone15plus/y.jpg.png")]
        },
        {
          "id": 2,
          "color": "#afd9c6",
          "title": "Зеленый",
          images: [require("./assets/images/Products/iphone15plus/g.jpg.png")]
        },
        {
          "id": 3,
          "color": "#ffc0cb",
          "title": "Розовый",
          images: [require("./assets/images/Products/iphone15plus/p.jpg.png")]
        },
        {
          "id": 4,
          "color": "#436397",
          "title": "Синий",
          images: [require("./assets/images/Products/iphone15plus/blu.jpg.png")]
        },
        {
          "id": 5,
          "color": "#3a3a3a",
          "title": "Черный",
          images: [require("./assets/images/Products/iphone15plus/black.jpg.png")]
        }
      ],
      memory: [
        {"id": 1, "value": "128 ГБ", price: 0},
        {"id": 2, "value": "256 ГБ", price: 6600},
        {"id": 3, "value": "512 ГБ", price: 14000},
      ],
    }],
  },
  {
    "id": 22,
    "product": "iphone",
    "name": "iPhone 15 Pro",
    "desc": "iPhone 15 Pro представляет собой воплощение эстетики и функциональности. Впервые рамка iPhone выполнена из титана, благодаря чему устройство является самым легким iPhone Pro в истории и способно выдерживать самые серьезные нагрузки. ",
    "price": 87900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#272826",
          "title": "Титановый черный",
          images: [require("./assets/images/Products/iphone15pro/b.jpeg.png")]
        },
        {
          "id": 2,
          "color": "#f0efec",
          "title": "Титановый белый",
          images: [require("./assets/images/Products/iphone15pro/w.jpeg.png")]
        },
        {
          "id": 3,
          "color": "#373c4b",
          "title": "Титановый синий",
          images: [require("./assets/images/Products/iphone15pro/blue.jpeg.png")]
        },
        {
          "id": 4,
          "color": "#aba69b",
          "title": "Титановый бежевый",
          images: [require("./assets/images/Products/iphone15pro/bej.jpeg.png")]
        }
      ],
      memory: [
        {"id": 1, "value": "128 ГБ", price: 0},
        {"id": 2, "value": "256 ГБ", price: 9000},
        {"id": 3, "value": "512 ГБ", price: 15900},
        {"id": 4, "value": "1 ТБ", price: 30000},
      ],
    }],
  },
  {
    "id": 23,
    "product": "iphone",
    "name": "iPhone 15 Pro Max",
    "desc": "iPhone 15 Pro Max представляет собой воплощение эстетики и функциональности. Впервые рамка iPhone выполнена из титана, благодаря чему устройство является самым легким iPhone Pro Max в истории и способно выдерживать самые серьезные нагрузки.",
    "price": 102900,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#272826",
          "title": "Титановый черный",
          images: [require("./assets/images/Products/iphone15pro/b.jpeg.png")]
        },
        {
          "id": 2,
          "color": "#f0efec",
          "title": "Титановый белый",
          images: [require("./assets/images/Products/iphone15pro/w.jpeg.png")]
        },
        {
          "id": 3,
          "color": "#373c4b",
          "title": "Титановый синий",
          images: [require("./assets/images/Products/iphone15pro/blue.jpeg.png")]
        },
        {
          "id": 4,
          "color": "#aba69b",
          "title": "Титановый бежевый",
          images: [require("./assets/images/Products/iphone15pro/bej.jpeg.png")]
        }
      ],
      memory: [
        {"id": 1, "value": "256 ГБ", price: 0},
        {"id": 2, "value": "512 ГБ", price: 18900},
        {"id": 3, "value": "1 ТБ", price: 34900},
      ],
    }],
  },
  {
    "id": 24,
    "product": "dyson",
    "name": "Фен Dyson Supersonic HD07",
    "desc": "Интеллектуальная система контроля температуры исходящего воздуха",
    "price": 41490,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#3a3a3a",
          "title": "Черный",
          images: [require("./assets/images/Products/dyson/hd07/black/wzesu562z433yjy0znv6sk9jf76ahqhf.jpg"),require("./assets/images/Products/dyson/hd07/black/20erb5yk55v0efl06515eymd92n857n0.jpg"),  require("./assets/images/Products/dyson/hd07/black/xd7l0cilxmd1zwzh64o410vdezslf223.jpg")]
        },
        {
          "id": 2,
          "color": "#969197",
          "title": "Никель-фуксия",
          images: [require("./assets/images/Products/dyson/hd07/nikel-fuc/d6d9xrrikrff03jqm76cpqrk24g2nw3q.jpg"), require("./assets/images/Products/dyson/hd07/nikel-fuc/6bwq9rng5uvjdhhxa7x4p70fsear8q3n.jpg"),  require("./assets/images/Products/dyson/hd07/nikel-fuc/qqc8dmexlrxd3yjeayd1yts5mmpcxwqt.jpg")]
        },
        {
          "id": 3,
          "color": "#c48e55",
          "title": "Никель-медь",
          images: [require("./assets/images/Products/dyson/hd07/nikel-med/8fb00ee1a4acfd47bdbd61a60e2a3174.jpg"), require("./assets/images/Products/dyson/hd07/nikel-med/91d35f3634d24f1af40f96672b8426aa.jpg"), require("./assets/images/Products/dyson/hd07/nikel-med/5a7db888f0087a6e180ddb2d03f005fe.jpg"), require("./assets/images/Products/dyson/hd07/nikel-med/f3bada2eec8f50ab5bd572d65431adee.jpg")]
        },
        {
          "id": 4,
          "color": "#ec359c",
          "title": "Фуксия",
          images: [require("./assets/images/Products/dyson/hd07/fuc/06zw3zgj28ejztj9t1xdizdxp0hz2tq9.jpg"), require("./assets/images/Products/dyson/hd07/fuc/dd87wx7mo41vb8p1e1bexkg8ywd9bkou.jpg"), require("./assets/images/Products/dyson/hd07/fuc/q5jzkvir6llmsv4nz531k0785g2mjm4o.jpg")]
        }
      ],
    }],
  },
  {
    "id": 25,
    "product": "dyson",
    "name": "Фен Dyson Supersonic HD08, 5 насадок",
    "desc": "Быстрое высушивание волос без экстремальных температур. Пять насадок для разных типов волос",
    "price": 47300,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#69b2d6",
          "title": "Голубой",
          images: [require("./assets/images/Products/dyson/hs08/blue-pink/o3i3jkk7fsdp3idoevckda87cjdui08f.jpg"), require("./assets/images/Products/dyson/hs08/blue-pink/bgy9l4pks4isshvfxylcsr7inkn6ye02.jpg"), require("./assets/images/Products/dyson/hs08/blue-pink/flac9lpk866wadmg8hrtjrx8re1ba6om.jpg")]
        },
        {
          "id": 2,
          "color": "#3a3a3a",
          "title": "Черный",
          images: [require("./assets/images/Products/dyson/hs08/black/e733064f8312f912cb7064cc9133d851.jpg"), require("./assets/images/Products/dyson/hs08/black/a31d3b8c37a43d2eece91d618c29504b.jpg"), require("./assets/images/Products/dyson/hs08/black/7cf65cf505bf59c5f22fb4a17c228fec.jpg") ]
        },
        {
          "id": 3,
          "color": "#969197",
          "title": "Никель-фуксия",
          images: [require("./assets/images/Products/dyson/hs08/nikel-fuc/9ed591ed5d8c37b8255bfe1e549f8e47.jpg"), require("./assets/images/Products/dyson/hs08/nikel-fuc/57f56eeb2752a6f219c132c6f80ca92d.jpg"), require("./assets/images/Products/dyson/hs08/nikel-fuc/a025d13d4b8e5b02ff7223ec15b68d1a.jpg")]
        },
        {
          "id": 4,
          "color": "#c48e55",
          "title": "Никель-медь",
          images: [require("./assets/images/Products/dyson/hs08/nikel-med/1de093c33a533553ecf3fdc36c9cea25.jpg"), require("./assets/images/Products/dyson/hs08/nikel-med/960b9b07380fa96732ce096b1fa59ebb.jpg"), require("./assets/images/Products/dyson/hs08/nikel-med/d6ac4b154a79cde3f9383258c8cf84cd.jpg")]
        },
        {
          "id": 5,
          "color": "#ec359c",
          "title": "Фуксия",
          images: [require("./assets/images/Products/dyson/hs08/fuc/u2ht0ghurge7luxg7xfto40uvq6bvfta.jpg"), require("./assets/images/Products/dyson/hs08/fuc/69deuiw9x6ww7a2n80g0uzxftcpglezj.jpg"),  require("./assets/images/Products/dyson/hs08/fuc/wofqja787j7qd41v7l7e64h4b8q0dqt3.jpg")]
        }
      ],
    }],
  },
  {
    "id": 26,
    "product": "dyson",
    "name": "Стайлер Dyson Airwrap Lite HS05",
    "desc": "Dyson Airwrap HS05 Complete Lite — это стайлер нового поколения, которое воплощает в себе современные технологии и инновации для создания безупречных причесок. Не только сушит волосы, но и стилизует их, придавая объем и форму, в то же время ухаживая за вашими волосами",
    "price": 54800,
    "count": 0,
    options: [{
      color: "disabled",
      images: [
        require("./assets/images/Products/dyson/styler-hs05/by9v633vb87bjuavnmibtq330suz0v5v.jpg"),
        require("./assets/images/Products/dyson/styler-hs05/6y8d8zwslu76wjv1euperxxf977nlgmp.jpg"),
        require("./assets/images/Products/dyson/styler-hs05/c7t02cmnyzbsl2rxndd8ws31xb7mvyu7.jpg"),
      ],
    }],
  },
  {
    "id": 27,
    "product": "dyson",
    "name": "Стайлер Dyson Airwrap HS05 Complete для коротких волос",
    "desc": "Dyson Airwrap HS05 Complete Lite — это стайлер нового поколения, которое воплощает в себе современные технологии и инновации для создания безупречных причесок. Не только сушит волосы, но и стилизует их, придавая объем и форму, в то же время ухаживая за вашими волосами",
    "price": 63090,
    "count": 0,
    options: [{
      color: "disabled",
      images: [
        require("./assets/images/Products/dyson/styler-hs05-c/0ixom524k7f83cizf73ojr73gg25slno.jpg"),
        require("./assets/images/Products/dyson/styler-hs05-c/4y6cki2gmlc8f91l43jfi4bgf04kjrfj.jpg"),
      ],
    }],
  },
  {
    "id": 28,
    "product": "dyson",
    "name": "Выпрямитель Dyson Corrale HS07,",
    "desc": "В два раза меньше повреждений волос. Улучшенный способ укладки.",
    "price": 47300,
    "count": 0,
    options: [{
      color: "disabled",
      images: [
        require("./assets/images/Products/dyson/vpr/35w3up50ovkshbj2knjxr3cqn6uoupv6.jpg"),
        require("./assets/images/Products/dyson/vpr/1tmupeh0818fywn9a16wi2hgdyqex50x.jpg"),
        require("./assets/images/Products/dyson/vpr/g81fmuarc28qsdwoh06fxykb10p3433r.jpg"),
        require("./assets/images/Products/dyson/vpr/gcwzljfx7fjirxec6rd135a47vyyidh9.jpg"),
      ],
    }],
  },
  {
    "id": 29,
    "product": "dyson",
    "name": "Выпрямитель Dyson Corrale HS03",
    "desc": "В два раза меньше повреждений волос. Улучшенный способ укладки.",
    "price": 36500,
    "count": 0,
    options: [{
      color: [
        {
          "id": 1,
          "color": "#3a3a3a",
          "title": "Черный",
          images: [require("./assets/images/Products/dyson/vprhs03/black-purple/w0gtrf1usz493kurx5sdz5382hyq171k.jpg"),require("./assets/images/Products/dyson/vprhs03/black-purple/uu8z3gtv1esuegyr3pnde53zhmpdei3z.jpg"), require("./assets/images/Products/dyson/vprhs03/black-purple/vs61wfpfdfix6x7n6s6036i41u07nwna.jpg"),  require("./assets/images/Products/dyson/vprhs03/black-purple/x70jjfuqf3yyu2aghwdgpn5nuqwh6fau.jpg")]
        },
        {
          "id": 2,
          "color": "#ec359c",
          "title": "Фуксия",
          images: [require("./assets/images/Products/dyson/vprhs03/fuc/2nlzpfw4g378esg5sc1mj4ldic9yq1kk.jpg"), require("./assets/images/Products/dyson/vprhs03/fuc/9flxkkijugbduizgww515dnnlrcjaikh.jpg"), require("./assets/images/Products/dyson/vprhs03/fuc/erb27q6j7n4ej7dypekb5alm0fczjioh.jpg"), require("./assets/images/Products/dyson/vprhs03/fuc/kklvkyc3254a40kkp4z4o5fl28f6m8li.jpg"), require("./assets/images/Products/dyson/vprhs03/fuc/m4ipu6a0hugv7f3bmkoivp0va4yxcsr4.jpg"), require("./assets/images/Products/dyson/vprhs03/fuc/vftp7c66ws8k7jy9zybl7szl8cdvcj3q.jpg")]
        },
        {
          "id": 3,
          "color": "#969197",
          "title": "Никель-фуксия",
          images: [require("./assets/images/Products/dyson/vprhs03/nikel-fuc/gibm9jy9m1h29kn9a839gr2b6ojbn4vi.jpg"), require("./assets/images/Products/dyson/vprhs03/nikel-fuc/ae76b5xkewipt6m48t1l6w5qgkwx4cw7.jpg"), require("./assets/images/Products/dyson/vprhs03/nikel-fuc/01uueboq85dg8gru6gq0t9u40ntvww3c.jpg"), require("./assets/images/Products/dyson/vprhs03/nikel-fuc/46tlc1h2l6w1txmjtt7u77vr9axukln6.jpg"), require("./assets/images/Products/dyson/vprhs03/nikel-fuc/ennor913e2keniapl5nkwvffrqpqk3q7.jpg")]
        },
        {
          "id": 4,
          "color": "#cf8c69",
          "title": "Медный",
          images: [require("./assets/images/Products/dyson/vprhs03/med/1bxu5p7g3a4d3w3luv6qq9h5hza1wdqy.jpg"),require("./assets/images/Products/dyson/vprhs03/med/dtq8dznjzx7l4smme0z2bvnbzlr48fpr.jpg"),require("./assets/images/Products/dyson/vprhs03/med/dvg0hexk9smi05slh6ps67mb9kftsqjs.jpg"),require("./assets/images/Products/dyson/vprhs03/med/uqbvorp7btnb1np3wwlb2aj6pugbzms4.jpg"),require("./assets/images/Products/dyson/vprhs03/med/i6l1ojdv150h8ag7rkndg9ynry8dqhxz.jpg")]
        },
      ],
    }],
  },
];