import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import menuReducer from '../redux/slices/menuSlice';
import productReducer from '../redux/slices/productSlice';
import orderReducer from '../redux/slices/orderSlice';
import userReducer from '../redux/slices/userSlice';

const rootReducer = combineReducers(
    {
        menu: menuReducer,
        product: productReducer,
        order: orderReducer,
        user: userReducer,
    }
);

const persistConfig = {
    key: 'root',
    storage,
}


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
})

export const persistor = persistStore(store);

export default store;

