import React from 'react';
import axios from "axios";
import * as yup from "yup";
import {CSSTransition} from "react-transition-group";
import styles from '../scss/ContactForm2.module.scss';
const CreditForm = () => {

    const [sendText, setText] = React.useState('Отправить');


    const ref = React.useRef();

    const [formData, setFormData] = React.useState({
            name: '',
            phone: '',
            address: '',
            date: '',
            doc: '',
            product: '',
            doc2: '',

    });

        const handleInputChange = (event) => {
            const { name, value } = event.target;
            setFormData({...formData, [name]: value});
        }

        const handleSubmit = (event) => {
            event.preventDefault();
            validate();
            if (Object.keys(formErrors).length === 0) {
                axios.post('https://one-iphonestore.ru/api/send-form-credit', formData)
                    .then((response) => {
                        console.log(response);

                    })
                    .catch((error) => {
                        console.log(error);
                    });

                setText('Отправка ...');
                setTimeout(() => {
                    setText('Отправлено!');
                    formData.name = '';
                    formData.phone = '';
                    formData.address = '';
                    formData.date = '';
                    formData.doc = '';
                    formData.doc2 = '';
                    formData.product = '';

                }, 2000);

                setTimeout(() => {
                    setText('Отправить');
                }, 10000);
            }
        }

        const validationSchema = yup.object().shape({
            name: yup.string().required('Обязательно *'),
            phone: yup.string().required('Обязательно *'),
            address: yup.string().required('Обязательно *'),
            doc: yup.string().required('Обязательно *'),
            product: yup.string().required('Обязательно *'),
            date: yup.string().required('Обязательно *'),
        });
        const [formErrors, setFormErrors] = React.useState({});

        const validate = () => {
            validationSchema.validate(formData, { abortEarly: false })
                .then(() => {
                    setFormErrors({});
                })
                .catch((error) => {
                    const errors = error.inner.reduce((acc, cur) => {
                        return { ...acc, [cur.path]: cur.message };
                    }, {});
                    setFormErrors(errors);
                });
        }

    const refDropdown2 = React.useRef(false);

    const refChooseContact = React.useRef('Перезвонить');


    const [isContact, openContact] = React.useState(false);
    const handleClickChoose = (e) => {
        refChooseContact.current.innerText = e.target.innerText;
        formData.doc2_name = e.target.innerText;
        openContact(!isContact);
    }

    const handleClickContact = () => {
        openContact(!isContact);
    }

    return (
        <div className="container">
            <div className={styles.section}>
                <div className={styles.text}>
                    <div>
                        <h4>Оставить заявку</h4>
                        <p>Мы свяжемся с вами, ответим на все интересующие вас вопросы.</p>
                        <form className={styles.form} onSubmit={handleSubmit}>

                            <div className={styles.form_row}>
                                <div className={styles.form_group}>
                                    <label htmlFor="name">ФИО</label>
                                    <input type="text" name="name" className={styles.form_control} value={formData.name} onChange={handleInputChange} onBlur={validate}/>
                                    <CSSTransition in={formErrors.name != null} timeout={300} classNames="error-message" unmountOnExit>
                                        <div className="error-message">{formErrors.name}</div>
                                    </CSSTransition>
                                </div>
                                <div className={styles.form_group}>
                                    <label htmlFor="phone">Телефон *</label>
                                    <input type="text" name="phone" className={styles.form_control} value={formData.phone} onChange={handleInputChange} onBlur={validate}/>
                                    <CSSTransition in={formErrors.name != null} timeout={300} classNames="error-message" unmountOnExit>
                                        <div className="error-message">{formErrors.phone}</div>
                                    </CSSTransition>
                                </div>
                            </div>

                            <div className={styles.form_row}>
                                <div className={styles.form_group}>
                                    <label htmlFor="address">Адрес доставки</label>
                                    <input type="text" name="address" className={styles.form_control} value={formData.address} onChange={handleInputChange} onBlur={validate}/>
                                    <CSSTransition in={formErrors.address != null} timeout={300} classNames="error-message" unmountOnExit>
                                        <div className="error-message">{formErrors.address}</div>
                                    </CSSTransition>
                                </div>
                            </div>

                            <div className={styles.form_row}>
                                <div className={styles.form_group}>
                                    <label htmlFor="doc">Паспорт №</label>
                                    <input type="text" name="doc" className={styles.form_control} value={formData.doc} onChange={handleInputChange} onBlur={validate}/>
                                    <CSSTransition in={formErrors.doc != null} timeout={300} classNames="error-message" unmountOnExit>
                                        <div className="error-message">{formErrors.doc}</div>
                                    </CSSTransition>
                                </div>
                                <div className={styles.form_group}>
                                    <label htmlFor="date">Кем выдат и дата выдачи</label>
                                    <input type="text" name="date" className={styles.form_control} value={formData.date} onChange={handleInputChange} onBlur={validate}/>
                                    <CSSTransition in={formErrors.date != null} timeout={300} classNames="error-message" unmountOnExit>
                                        <div className="error-message">{formErrors.date}</div>
                                    </CSSTransition>
                                </div>
                            </div>

                            <div className={styles.form_row}>
                                <div className={styles.form_group}>
                                        <div className={styles.form_contact}>
                                            <p>Второй документ?</p>
                                            <div className={styles.choosecontact}>
                                                <div className={styles.input} onClick={() => handleClickContact()}>
                                      <span style={{display: isContact ? 'none' : ''}}><strong ref={refChooseContact}>СНИЛС</strong><svg height="7px" width="7px" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="#000" d="M 1.4 6.99441e-15L 0 1.34043L 5 6L 10 1.34043L 8.6 0L 5 3.34998L 1.4 6.99441e-15Z"></path>
                                        </svg></span>

                                                    {
                                                        isContact && (
                                                            <div ref={refDropdown2} className={styles.dropdown}>
                                                                <ul onClick={(e) => handleClickChoose(e)}>
                                                                    <li>СНИЛС</li>
                                                                    <li>Пенсионное</li>
                                                                    <li>Водительское удостоверение</li>
                                                                    <li>Вид на жительство</li>
                                                                    <li>Военный билет</li>
                                                                    <li>Другое</li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    }

                                                </div>


                                            </div>

                                        </div>



                                    <CSSTransition in={formErrors.doc != null} timeout={300} classNames="error-message" unmountOnExit>
                                        <div className="error-message">{formErrors.doc}</div>
                                    </CSSTransition>
                                </div>
                                <div className={styles.form_group}>
                                    <label htmlFor="doc2">№ второго документа</label>
                                    <input type="text" name="doc2" className={styles.form_control} value={formData.doc2} onChange={handleInputChange} onBlur={validate}/>
                                    <CSSTransition in={formErrors.doc2 != null} timeout={300} classNames="error-message" unmountOnExit>
                                        <div className="error-message">{formErrors.doc2}</div>
                                    </CSSTransition>
                                </div>
                            </div>

                            <div className={styles.form_row}>
                                <div className={styles.form_group}>
                                    <label htmlFor="product">Наименование товара</label>
                                    <input type="text" name="product" className={styles.form_control} value={formData.product} onChange={handleInputChange} onBlur={validate}/>
                                    <CSSTransition in={formErrors.product != null} timeout={300} classNames="error-message" unmountOnExit>
                                        <div className="error-message">{formErrors.product}</div>
                                    </CSSTransition>
                                </div>
                            </div>

                            <div className={styles.button}>
                                <button type="submit" className={styles.send_button}>
                                    <span ref={ref}>{sendText} <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span>
                                </button>
                            </div>
                        </form>
                        <span>Нажимая на кнопку, вы даете согласие на обработку персональных данных</span>
                    </div>



                </div>

            </div>
        </div>
    );
}

export default CreditForm
