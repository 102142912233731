import Login from "../components/Login";

function LoginPage() {
    return (
        <>
            <h1>Login</h1>
            <Login/>
        </>
    );
}

export default LoginPage;
