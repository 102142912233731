import styles from '../scss/Garant.module.scss';
import React from "react";

function Garant() {
    return (
        <>
            <div className="wrapper">
                <div className="container">
                    <div className={styles.section}>
                        <div className={styles.garant}>
                            <div className={styles.question}>
                                Приобретая технику в нашем интернет магазине, мы хотим чтобы каждый клиент чувствовал полную уверенность в нас. Мы знаем как тяжело заработать правильную репутацию в интернете, поэтому обещаем делать всё зависящее от нас для того, чтобы вы оставались довольны качеством нашего сервиса. Наш магазин с одинаковой ответственностью относится как к крупным оптовым сделкам, так и к маленьким разовым покупкам.
                            </div>
                            <div className={styles.legal}>
                                <h4>Юридический аспект</h4>
                                <div className={styles.items}>
                                    <div className={styles.item}>
                                        <ul>
                                            <li>ООО "ПЛАЗА-ОПТ"</li>
                                            <li>ОГРН 1155658033387</li>
                                            <li>ИНН 5603043557</li>
                                            <li>КПП 560301001</li>
                                            <li>Генеральный директор Барышев Александр Анатольевич</li>
                                        </ul>

                                    </div>


                                    <div className={styles.item}>
                                        <ul>
                                            <li><a href="check3.jpg" target="_blank">Счет на оплату</a></li>
                                            <li><a href="TAX%20Invoice.pdf" target="_blank">Tax Invoice</a></li>
                                            <li><a href="egrul.pdf" target="_blank">Выписка из ЕГРЮЛ</a></li>
                                            <li><a href="dog.pdf" target="_blank">Договор купли-продажи и поставки товара</a></li>
                                            <li><a href="ustav3.pdf" target="_blank">Устав</a></li>
                                        </ul>
                                    </div>

                                    <div className={styles.item}>
                                        <h5>
                                            ГОСТ Р СЕРТИФИКАТЫ СООТВЕТСТВИЯ
                                        </h5>
                                        <ul>
                                            <li>ГОСТ Р Сертификат (Apple) от 24.07.2017 Рег.№248542-11АА</li>
                                            <li>ГОСТ Р Сертфикат (Samsung) от 15.10.2017 Рег.№248543-11АА</li>
                                            <li>ГОСТ Р Сертификат (Xaomi) от 15.10.2017 Рег.№248582-11АА</li>
                                            <li>ГОСТ Р Сертификат (JBL) от 18.10.2017 Рег.№248599-11АА</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.legal}>
                                <h4>Социальные сети</h4>
                                <div className={styles.items}>
                                    <div className={styles.item}>
                                        <h5>
                                            ВКОНТАКТЕ
                                        </h5>
                                        <ul>
                                            <li>Политика деятельности нашего магазина заключается в концепции максимальной прозрачности перед нашими клиентами.</li>
                                            <li>В разделе “Контакты” нашей группы Вконакте мы открыто показываем ЛИЧНЫЕ страницы менеджеров, директора, нашего бухгалтера.</li>
                                            <li>Вы можете пройти на каждую из них и увидеть, что фотографии сделаны несколько лет назад, работники активно ведут свои страницы.</li>
                                            <li>Таким образом общаясь с нами, вы можете быть уверенны в том, что на другой стороне экрана находится такой же человек как и вы.</li>
                                            <li>Возможно, многие подумают, что это мелочь, но мы и дальше будем максимально прозрачны для вас – потому что только таким образом мы вместе сможем выстроить долгосрочные и эффективные отношения! </li>
                                        </ul>
                                    </div>
                                    <div className={styles.item}>
                                        <h5>
                                            INSTAGRAM
                                        </h5>
                                        <ul>
                                           <li>Обратите внимание на нашу страницу Instagram, ведь мы вложили в неё кучу времени и сил!</li>
                                           <li>Мы стараемся регулярно публиковать что-либо из товара, отзывы клиентов или просто что-нибудь, связанное с нашим любимым делом!</li>
                                           <li>Каждый месяц мы вкладываем в рекламу несколько десятков тысяч рублей.</li>
                                           <li>Наша страница в Instagram – это наша гордость!</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.legal}>
                                <h4>Прием платежей</h4>
                                <div className={styles.items}>
                                    <div className={styles.item}>
                                        <ul>
                                            <li>Произвести оплату за свою покупку в нашем магазине возможно банковским переводом.</li>
                                            <li>Для удобства клиентов мы можем предоставить как рублёвый, так и валютный счет, открытый сразу в нескольких банках Российской Федерации.</li>
                                            <li>Для клиентов из СНГ мы можем принять оплату в любой электронно-платежной системе.</li>
                                            <li>Свою покупку на сумму до 15 тысяч рублей клиент может оплатить непосредственно на личную карту Генерального Директора нашего магазина. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Garant