import React from "react";
import {data} from '../products.jsx';
import { getDatabase, ref, child, get } from "firebase/database";
import {Link, useParams} from 'react-router-dom';
import styles from '../scss/Reviews.module.scss';
import {Slide} from "react-slideshow-image";
import {CSSTransition} from "react-transition-group";
import axios from "axios";
import * as yup from "yup";
import FormEmail from "../components/FormEmail";
import SendReview from "../components/SendReview";
import {useDispatch, useSelector} from "react-redux";
import {openContactForm, openReviewForm} from "../redux/slices/menuSlice";

import avatar from '../assets/images/Disc_Plain_black-svg.png';

function Reviews() {

    const isVisible = useSelector((state) => state.menu.review);
    const dispatch = useDispatch();

    const params = useParams();
    const paramsId = params.reviewId - 1;
    const productId = params.reviewId;
    const id = data[paramsId].id;

    const dbRef = ref(getDatabase());

    const [isData, setData] = React.useState([]);

    const [isMounted, setMounted] = React.useState(true);

    const product = data[Number(paramsId)];


    React.useEffect(() => {
        //if(data[params.reviewId]) {
            if(isMounted) {
                get(child(dbRef, 'reviews')).then((snapshot) => {
                    if (snapshot.exists()) {

                        const items = snapshot.val();
                        const obj = Object.keys(items).map((key) => {
                            return { id: key, ...items[key] };
                        });

                        setData(obj);


                        setMounted(false);

                    } else {
                        console.log("No data available");
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }
            console.log(isData);
        //}
    }, [isData]);

    console.log(isData)
    const filteredData = isData.filter((item) => item.productid === productId);
    const sortedData = filteredData.sort((a, b) => {
        const dateA = new Date(a.time.split('.').reverse().join('-'));
        const dateB = new Date(b.time.split('.').reverse().join('-'));

        return dateB - dateA;
    });
    console.log(filteredData);


    return (
        <>
            <div className="wrapper">
                <div className="container">
                    <div className={styles.section}>
                        <h1>Отзывы</h1>
                        <div className={styles.product}>
                            <div className={styles.product_item}>
                                <div className={styles.p_image}>
                                    {
                                        (product.options[0].color !== "disabled") && (

                                            product.options[0].color[0].images.length >= 2 ? (
                                                <Slide transitionDuration={250} autoplay={false}>
                                                    {
                                                        product.options[0].color[0].images.map((item,index) => {
                                                            return (
                                                                <div key={index + item} className="each-slide-effect">
                                                                    <div style={{ 'backgroundImage': `url(${item})` }}>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </Slide>
                                            ) : (
                                                <img src={product.options[0].color[0].images[0]} alt="product"/>
                                            )
                                        )
                                    }

                                    {
                                        product.options[0].images && (
                                            <Slide transitionDuration={250}>
                                                {
                                                    product.options[0].images.map((item,index) => {
                                                        return (
                                                            <div key={item + index} className="each-slide-effect">
                                                                <div style={{ 'backgroundImage': `url(${item})` }}>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </Slide>
                                        )
                                    }


                                    {/* <img src={options[0].images[0]}/>*/}




                                </div>
                                <div className={styles.p_info}>
                                    <h4>{product.name}</h4>

                                    {
                                        product.options[0].generation && (
                                            <div className={styles.code}>
                                                Артикул: <span>{product.options[0].generation[0].code}</span>
                                            </div>
                                        )
                                    }

                                    {
                                        product.code && (
                                            <div className={styles.code}>
                                                Артикул: <span>{product.code}</span>
                                            </div>
                                        )
                                    }

                                    <div className={styles.p_desc}>
                                        <p>{product.desc}</p>
                                    </div>

                                    <div className={styles.button}>
                                        <button className={styles.send_button} onClick={() => dispatch(openReviewForm(!isVisible))}>
                                            <span>Оставить отзыв<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span>
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className={styles.items}>
                            {sortedData.filter(item => item.active === 1).map((item, index) => (

                                <div key={index} className={styles.item}>
                                    <h4>{item.name}<span>{item.time}</span></h4>

                                    {/*<h5>Достоинства</h5>*/}
                                    {/*<p>*/}
                                    {/*    {item.plus.split('\n').map((line, lineIndex) => (*/}
                                    {/*        <React.Fragment key={lineIndex}>*/}
                                    {/*            {line}*/}
                                    {/*            <br />*/}
                                    {/*        </React.Fragment>*/}
                                    {/*    ))}*/}
                                    {/*</p>*/}
                                    {/*<h5>Недостатки</h5>*/}
                                    {/*<p>*/}
                                    {/*    {item.minus.split('\n').map((line, lineIndex) => (*/}
                                    {/*        <React.Fragment key={lineIndex}>*/}
                                    {/*            {line}*/}
                                    {/*            <br />*/}
                                    {/*        </React.Fragment>*/}
                                    {/*    ))}*/}
                                    {/*</p>*/}

                                    <h5>Отзыв</h5>
                                    <p>
                                        {item.review.split('\n').map((line, lineIndex) => (
                                        <React.Fragment key={lineIndex}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                        ))}
                                    </p>

                                    <h5>Комментарий магазина</h5>
                                    <div className={styles.admin}>
                                        <div className={styles.avatar}><img src={avatar} alt="admin"/></div>
                                        <div className={styles.text}>
                                            <p className={styles.admin__name}>Менеджер Илья</p>
                                            <p>
                                                {item.comment.split('\n').map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        {line}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                        </div>
                                    </div>


                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {
                isVisible && (
                    <SendReview id={productId}/>
                )
            }
        </>

    )
}

export default Reviews;