import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    // appId: process.env.REACT_APP_FIREBASE_APP_ID,
    // measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID
    apiKey: "AIzaSyCgmFMUW3If52MaNjW0s025A937YtLuHyU",
    authDomain: "apple-shop-6dd0c.firebaseapp.com",
    projectId: "apple-shop-6dd0c",
    storageBucket: "apple-shop-6dd0c.appspot.com",
    messagingSenderId: "188315301104",
    appId: "1:188315301104:web:2c7e35050a8b6c3bc53e35",
    measurementId: "G-EF7FJT13SW"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);