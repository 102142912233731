import React from "react";
import { Link } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setMemory} from "../redux/slices/productSlice";
import {addItem} from "../redux/slices/orderSlice";
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import styles from "../scss/Iphone.module.scss";
function Product({id, product, name, desc, price, code, options}) {


    const [isActiveMemory, SetActiveMemory] = React.useState(0);
    const [memory_price, setMemoryPrice] = React.useState(0);

    const [isActiveColor, SetActiveColor] = React.useState(0);

    const [generation_price, setGenerationPrice] = React.useState(0);
    const [isActiveGen, setActiveGen] = React.useState(0);

    const [size_price, setSizePrice] = React.useState(0);
    const [isActiveSize, setActiveSize] = React.useState(0);

    const [net_price, setNetPrice] = React.useState(0);
    const [isActiveNet, setActiveNet] = React.useState(0);

    const [ram_price, setRamPrice] = React.useState(0);
    const [isActiveRam, setActiveRam] = React.useState(0);

    const [method, setMethod] = React.useState(0);
    const [method_price, setMethodPrice] = React.useState(0);
    const [isActiveMethod, setActiveMethod] = React.useState(2);

    React.useEffect(() => {
        //SetActiveMemory(0);
        //SetActiveColor(0);

        const memory = options[0].memory === "" ? options[0].memory[0].price : 0
        setMemoryPrice(memory);

        const generation = options[0].generation === "" ? options[0].generation[0].price : 0
        setGenerationPrice(generation);

        const size = options[0].size === "" ? options[0].size[0].price : 0
        setActiveSize(size);

        const net = options[0].net === "" ? options[0].net[0].price : 0
        setActiveNet(net);

        const ram = options[0].ram === "" ? options[0].ram[0].price : 0
        setActiveRam(ram);

        const method_price = (price / 100) * 20
        setMethodPrice(method_price);

    }, []);

    const onClickAdd = () => {
        const item = {
            id,
            name,
            desc,
            price,
            options,
            isActiveMemory,
            isActiveColor,
            isActiveGen,
            isActiveSize,
            isActiveNet,
            isActiveRam,
            isActiveMethod,
            memory_price,
            generation_price,
            size_price,
            net_price,
            ram_price,
        }
        //console.log(item);
        dispatch(addItem(item));

        // const getButtonText = refAdd.current.innerText;
        //
        // refAdd.current.innerText = "OK";
        //
        // console.log(refAdd.current.innerText);
        // setTimeout(() => {
        //     refAdd.current.innerText = "В корзину";
        // }, 2000);
        //


    }

    const refImage = React.useRef();
    const refCode = React.useRef();
    const refAdd = React.useRef();

    const setColor = (options, id) => {
        if(options.images.length === 0) {
            refImage.current.src = options.images[0]
        }

        if(options.code) {

        }

        SetActiveColor(id);
        console.log(options.images)
    }

    const isSetMemory = useSelector((state) => state.product.memory);
    const dispatch = useDispatch();

    const setMemoryStyle = {
        border: '2px solid #000',
        transition: 'transform 150ms ease',
    }

    const clickMemory = (memory, index) => {
        SetActiveMemory(index);
        setMemoryPrice(options[0].memory[index].price)
    }

    const clickGeneration = (gen, index) => {
        setActiveGen(index);
        setGenerationPrice(options[0].generation[index].price);
        refCode.current.innerText = options[0].generation[index].code
    }

    const clickSize = (size, index) => {
        setActiveSize(index);
        setSizePrice(options[0].size[index].price);
    }

    const clickNet = (size, index) => {
        setActiveNet(index);
        setNetPrice(options[0].net[index].price);
    }


    const clickRam = (size, index) => {
        setActiveRam(index);
        setRamPrice(options[0].ram[index].price);
    }



    const methods = [
        {"id": 1, "value":"Наложенный платёж", "img": require('../assets/images/cod.svg').default},
        {"id": 2, "value":"Скидка 15%", "img": require('../assets/images/pric.svg').default},
        {"id": 3, "value":"TAX-Free возврат 22% ", "img": require('../assets/images/retu.svg').default},
    ]

    const clickMethod = (size, index) => {
        setActiveMethod(index);
        setMethod(methods[index].id);

        if(index === 0) {
            setMethodPrice((((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 20).toFixed(0))
        }else if(index === 1) {
            setMethodPrice((((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 75).toFixed(0))
        }
        console.log(method_price);
    }

    const images = options[0].images;

    return (
        <div className={styles.product_item}>
            <div className={styles.p_image}>
                {
                    (options[0].color !== "disabled") && (

                        options[0].color[isActiveColor].images.length >= 2 ? (
                            <Slide transitionDuration={250} autoplay={false}>
                                {
                                    options[0].color[isActiveColor].images.map((item,index) => {
                                        return (
                                            <div key={index + item} className="each-slide-effect">
                                                <div style={{ 'backgroundImage': `url(${item})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </Slide>
                            ) : (
                                <img ref={refImage} src={options[0].color[isActiveColor].images[0]} alt="product"/>
                            )
                    )
                }

                {
                    options[0].images && (
                        <Slide transitionDuration={250}>
                            {
                                options[0].images.map((item,index) => {
                                    return (
                                        <div key={item + index} className="each-slide-effect">
                                            <div style={{ 'backgroundImage': `url(${item})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}>
                                            </div>
                                        </div>
                                    );
                                })}
                        </Slide>
                    )
                }


                {/* <img src={options[0].images[0]}/>*/}




            </div>
            <div className={styles.p_info}>
                {
                    options[0].generation && (
                        <div className={styles.code}>
                            Артикул: <span ref={refCode}>{options[0].generation[0].code}</span>
                        </div>
                    )
                }

                {/*{*/}
                {/*    active_code !== "undefined" && (*/}
                {/*        <div className={styles.code}>*/}
                {/*            Артикул: <span>{active_code.code}</span>*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}

                {
                    code && (
                        <div className={styles.code}>
                            Артикул: <span>{code}</span>
                        </div>
                    )
                }



                <div className={styles.p_title}>
                    <h4>{name}</h4>
                    <Link to={`/reviews/${id}`} className={styles.reviews}><p>Отзывы</p></Link>
                </div>
                <div className={styles.p_desc}>
                    <p>{desc}</p>
                </div>

                {
                    options[0].net && (
                        <div className={styles.p_memory}>
                            <p>возможность подключения</p>
                            <div className={styles.p_memory_switch}>
                                <ul className={styles.p_memory_ul}>
                                    {
                                        options[0].net.map((item,index) => {
                                            return (
                                                <li key={index}
                                                    onClick={() => clickNet(item.value, index)}
                                                    className={isActiveNet === index ? styles.active : ''}>
                                                    {item.value}
                                                </li>
                                            );
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    )
                }

                {
                    options[0].ram && (
                        <div className={styles.p_memory}>
                            <p>оперативная память</p>
                            <div className={styles.p_memory_switch}>
                                <ul className={styles.p_memory_ul}>
                                    {
                                        options[0].ram.map((item,index) => {
                                            return (
                                                <li key={index}
                                                    onClick={() => clickRam(item.value, index)}
                                                    className={isActiveRam === index ? styles.active : ''}>
                                                    {item.value}
                                                </li>
                                            );
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    )
                }

                {
                    options[0].memory && (
                        <div className={styles.p_memory}>
                            <p>объем памяти</p>
                            <div className={styles.p_memory_switch}>
                                <ul className={styles.p_memory_ul}>
                                    {
                                        options[0].memory.map((item,index) => {
                                            return (
                                                <li key={index}
                                                    onClick={() => clickMemory(item.value, index)}
                                                    className={isActiveMemory === index ? styles.active : ''}>
                                                    {item.value}
                                                </li>
                                            );
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    )
                }

                {
                    options[0].size && (
                        <div className={styles.p_memory}>
                            <p>размер</p>
                            <div className={styles.p_memory_switch}>
                                <ul className={styles.p_memory_ul}>
                                    {
                                        options[0].size.map((item,index) => {
                                            return (
                                                <li key={index}
                                                    onClick={() => clickSize(item.value, index)}
                                                    className={isActiveSize === index ? styles.active : ''}>
                                                    {item.value}
                                                </li>
                                            );
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    )
                }

                {
                    (options[0].color !== 'disabled') && (
                        <div className={styles.p_color}>
                            <p>цвет</p>
                            <div className={styles.p_color_switch}>
                                {options[0].color.map((options, index) =>
                                    <div key={options + index} onClick={() => setColor(options, index)} style={{background: options.color}} className={styles.p_color_set}>
                                        <svg className={isActiveColor === index ? styles.active : ''} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.7071 2.29289C12.0976 2.68342 12.0976 3.31658 11.7071 3.70711L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L1.29289 6.70711C0.902369 6.31658 0.902369 5.68342 1.29289 5.29289C1.68342 4.90237 2.31658 4.90237 2.70711 5.29289L5 7.58579L10.2929 2.29289C10.6834 1.90237 11.3166 1.90237 11.7071 2.29289Z" fill="white"/>
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }

                {
                    options[0].generation && (
                        <div className={styles.p_memory}>
                            <p>поколение</p>
                            <div className={styles.p_memory_switch}>
                                <ul className={styles.p_memory_ul}>
                                    {
                                        options[0].generation.map((item,index) => {
                                            return (
                                                <li key={index}
                                                    onClick={() => clickGeneration(item.value, index)}
                                                    className={isActiveGen === index ? styles.active : ''}>
                                                    {item.value}
                                                </li>
                                            );
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    )
                }


                {/*{*/}
                {/*    methods && (*/}
                {/*        <div className={styles.p_memory}>*/}
                {/*            <p>способ оплаты</p>*/}
                {/*            <div className={styles.p_memory_switch}>*/}
                {/*                <ul className={styles.p_memory_ul}>*/}
                {/*                    {*/}
                {/*                        methods.map((item,index) => {*/}
                {/*                            return (*/}
                {/*                                <li key={index}*/}
                {/*                                    onClick={() => clickMethod(item.value, index)}*/}
                {/*                                    className={isActiveMethod === index ? styles.active : ''}>*/}
                {/*                                    {item.value}*/}
                {/*                                </li>*/}
                {/*                            );*/}
                {/*                        })*/}
                {/*                    }*/}

                {/*                </ul>*/}

                {/*                <div className={styles.payment}>*/}
                {/*                    {*/}
                {/*                        isActiveMethod === 0 && (*/}
                {/*                            <p>Предоплата {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 20).toFixed(0)} р. (20%), при получении платится {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 80).toFixed(0)} р. (80%)</p>*/}
                {/*                        )*/}
                {/*                    }*/}

                {/*                    {*/}
                {/*                        isActiveMethod === 1 && (*/}
                {/*                            <p>Цена со скидкой: {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 85).toFixed(0)} р. (-15%), полная предоплата</p>*/}
                {/*                        )*/}
                {/*                    }*/}

                {/*                    {*/}
                {/*                        isActiveMethod === 2 && (*/}
                {/*                            <p>Возврат: {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 22).toFixed(0)} р. (22%), полная предоплата</p>*/}
                {/*                        )*/}
                {/*                    }*/}

                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}



                    {options[0].ram ? (
                        options[0].ram[isActiveRam].sell !== "false" ? (

                            <>
                                {
                                    methods && (
                                        <div className={styles.p_memory}>
                                            <p>способ оплаты</p>
                                            <div className={styles.p_memory_switch}>
                                                <ul className={styles.p_memory_ul}>
                                                    {
                                                        methods.map((item,index) => {
                                                            return (
                                                                <li key={index}
                                                                    onClick={() => clickMethod(item.value, index)}
                                                                    className={isActiveMethod === index ? styles.active : ''}>
                                                                    <img src={item.img}/>
                                                                    {item.value}
                                                                </li>
                                                            );
                                                        })
                                                    }

                                                </ul>

                                                <div className={styles.payment}>
                                                    {
                                                        isActiveMethod === 0 && (
                                                            <p>Предоплата {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 20).toFixed(0)} р. (20%), при получении платится {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 80).toFixed(0)} р. (80%)</p>
                                                        )
                                                    }

                                                    {
                                                        isActiveMethod === 1 && (
                                                            <p>Цена со скидкой: {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 85).toFixed(0)} р. (-15%), полная предоплата</p>
                                                        )
                                                    }

                                                    {
                                                        isActiveMethod === 2 && (
                                                            <p>Возврат: {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 22).toFixed(0)} р. (22%), полная предоплата</p>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }


                                <div className={styles.p_cost}>
                                    <button ref={refAdd}  className={styles.buy} onClick={() => onClickAdd()}><span>В корзину <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span></button>

                                    <div className={styles.p_show_cost}>
                                        <h4 style={isActiveMethod !== 2 ? {textDecoration: "line-through", fontSize: "14px"} : {}}>{(price + memory_price + generation_price + size_price + net_price + ram_price)} р.</h4>

                                        {
                                            isActiveMethod === 0 && (
                                                <span>{(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 20).toFixed(0)} р.</span>
                                            )
                                        }

                                        {
                                            isActiveMethod === 1 && (
                                                <span>{(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 85).toFixed(0)} р.</span>
                                            )
                                        }

                                    </div>
                                </div>
                            </>


                        ) : (
                            <div className={styles.p_cost}>
                            <div className={styles.p_show_cost}>
                                <h4>Нет в продаже</h4>
                            </div>
                            </div>
                        )
                    ) : (

                        <>

                            {
                                methods && (
                                    <div className={styles.p_memory}>
                                        <p>способ оплаты</p>
                                        <div className={styles.p_memory_switch}>
                                            <ul className={styles.p_memory_ul}>
                                                {
                                                    methods.map((item,index) => {
                                                        return (
                                                            <li key={index}
                                                                onClick={() => clickMethod(item.value, index)}
                                                                className={isActiveMethod === index ? styles.active : ''}>
                                                                <img src={item.img}/>
                                                                {item.value}
                                                            </li>
                                                        );
                                                    })
                                                }

                                            </ul>

                                            <div className={styles.payment}>
                                                {
                                                    isActiveMethod === 0 && (
                                                        <p>Предоплата {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 20).toFixed(0)} р. (20%), при получении платится {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 80).toFixed(0)} р. (80%)</p>
                                                    )
                                                }

                                                {
                                                    isActiveMethod === 1 && (
                                                        <p>Цена со скидкой: {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 85).toFixed(0)} р. (-15%), полная предоплата</p>
                                                    )
                                                }

                                                {
                                                    isActiveMethod === 2 && (
                                                        <p>Возврат: {(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 22).toFixed(0)} р. (22%), полная предоплата</p>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            <div className={styles.p_cost}>
                                <button ref={refAdd}  className={styles.buy} onClick={() => onClickAdd()}><span>В корзину <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span></button>

                                <div className={styles.p_show_cost}>
                                    <h4 style={isActiveMethod !== 2 ? {textDecoration: "line-through", fontSize: "14px"} : {}}>{(price + memory_price + generation_price + size_price + net_price + ram_price)} р.</h4>

                                    {
                                        isActiveMethod === 0 && (
                                            <span>{(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 20).toFixed(0)} р.</span>
                                        )
                                    }

                                    {
                                        isActiveMethod === 1 && (
                                            <span>{(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 85).toFixed(0)} р.</span>
                                        )
                                    }

                                </div>
                            </div>
                        </>


                    )}





                {/*{*/}
                {/*    options[0].ram && (options[0].ram[isActiveRam].sell !== "false" ? (*/}
                {/*        <div className={styles.p_cost}>*/}
                {/*            <button ref={refAdd}  className={styles.buy} onClick={() => onClickAdd()}><span>В корзину <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span></button>*/}
                {/*            <div className={styles.p_show_cost}>*/}
                {/*                <h4 style={isActiveMethod !== 2 ? {textDecoration: "line-through", fontSize: "14px"} : {}}>{(price + memory_price + generation_price + size_price + net_price + ram_price)} р.</h4>*/}

                {/*                {*/}
                {/*                    isActiveMethod === 0 && (*/}
                {/*                        <span>{(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 20).toFixed(0)} р.</span>*/}
                {/*                    )*/}
                {/*                }*/}

                {/*                {*/}
                {/*                    isActiveMethod === 1 && (*/}
                {/*                        <span>{(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 85).toFixed(0)} р.</span>*/}
                {/*                    )*/}
                {/*                }*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*    ) : (*/}
                {/*        <div className={styles.p_cost}>*/}
                {/*            <div className={styles.p_show_cost}>*/}
                {/*                <h4>Нет в продаже</h4>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    ))*/}
                {/*}*/}



            </div>
        </div>
    );
}

export default Product