
import {useDispatch} from "react-redux";
//import {Form} from './Form'
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {setUser} from "../redux/slices/userSlice";
import React from "react";
import {useNavigate} from "react-router-dom";
import styles from "../scss/Dashboard.module.scss";
import {setDelivery} from "../redux/slices/orderSlice";

const Login = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = React.useState('');
    const [password, setPass] = React.useState('');

    const [error, setError] = React.useState(true);

    const push = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then(({user}) => {
                console.log(user);
                dispatch(setUser({
                    email: user.email,
                    id: user.uid,
                    token: user.accessToken,
                }));
                setError(false);
                push("/dashboard");
            })
            .catch((e) => {
                setError(true)
            })
    }

    React.useEffect(() => {

            setError(false)

    }, [email, password]);

    return (
        <div className="sign-in-container" style={{paddingTop: '120px', display: "flex", justifyContent: "center", paddingBottom: "80px"}}>

            <form className={styles.form} onSubmit={handleLogin}>
                <div className={styles.form_group}>
                    <label htmlFor="email">Администратор</label>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email"className={styles.form_control}/>
                </div>
                <div className={styles.form_group}>
                    <label htmlFor="password">Пароль</label>
                    <input type="password" value={password} onChange={(e) => setPass(e.target.value)} placeholder="password" className={styles.form_control}/>
                </div>
                <div className={styles.button}>
                    <button type="submit" className={styles.send_button}>
                        <span>Войти <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span>
                    </button>
                    {
                        error && (
                            <p>Произошла ошибка при входе. Проверьте корректность введенных данных</p>
                        )
                    }

                </div>

            </form>

        </div>
    )
}

export default Login;