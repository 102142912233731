import React from "react";
import styles from '../scss/Footer.module.scss';
import logo from '../assets/images/logo.png';
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div className="wrapper full-background" style={{background: "#000"}}>
            <div className="container" style={{background: "#000"}}>
                <div className={styles.section}>
                    <div className={styles.logo}>
                        <img src={logo}/>
                    </div>

                    <div className={styles.footer_content}>
                        <div className={styles.lists}>
                            <div className={styles.list}>
                                <p>Навигация</p>
                                <ul>
                                    <Link to="/iphone"><li>iPhone</li></Link>
                                    <Link to="/airpods"><li>AirPods</li></Link>
                                    <Link to="/watch"><li>Watch</li></Link>
                                    <Link to="/mac"><li>Mac</li></Link>
                                    <Link to="/"><li>Dyson</li></Link>
                                    <Link to="/accessories"><li>Аксессуары</li></Link>
                                </ul>
                            </div>
                            <div className={styles.list}>
                                <p>Полезные ссылки</p>
                                <ul>
                                    <Link to="/service"><li>Сервисный центр</li></Link>
                                    <Link to="/about"><li>О компании</li></Link>
                                    <Link to="/contacts"><li>Контакты</li></Link>
                                    <Link to="/delivery"><li>Доставка и оплата</li></Link>
                                    <Link to="/"><li>Подарочный сертификат</li></Link>
                                </ul>
                            </div>
                        </div>

                        <div className={styles.footer_br}>
                            <div className={styles.contact}>
                                <div className={styles.phone}>
                                    <div className={styles.chat}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon" fill="#fff"><path d="M4 14v-2H3a1.99 1.99 0 0 1-1.773-1.093A.49.49 0 0 1 1 10.5V3.777c-.61.55-1 1.336-1 2.22v4a3 3 0 0 0 3 3v3l3.75-3H9v-1H6.666L4 14zm9-14H5a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h4.25L13 13v-3a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3zm2 7a2 2 0 0 1-2 2h-1v2L9.33 9H5a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v4z"/></svg>
                                    </div>
                                    <div>
                                        <a href="tel:+79014179922" className={styles.tel}>+7 (901) 417-99-22</a>
                                        <span>оформление заказов и поддержка</span>
                                    </div>
                                </div>
                                <div className={styles.links}>
                                    <a href="https://wa.me/79014179922" target="_blank" className={styles.icon}>
                                        <svg fill="#fff" version="1.1" id="Layer_1"
                                             xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                             viewBox="0 0 308 308" xmlSpace="preserve">
                                            <g id="XMLID_468_">
                                                <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
		c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
		c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
		c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
		c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
		c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
		c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
		c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
		c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
		C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                                                <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
		c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
		c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
		 M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
		l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
		c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
		C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                                            </g>
                                        </svg>
                                    </a>
                                    {/*<a href="https://vk.com/onestore.shop" target="_blank" className={styles.icon}>*/}
                                    {/*    <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3333 1980" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd"><path d="M3257 134c23-77 0-134-110-134h-365c-93 0-135 49-159 103 0 0-185 452-448 746-85 85-124 112-170 112-23 0-58-27-58-104V135c0-93-26-134-103-134h-573c-58 0-93 43-93 84 0 88 131 108 145 355v537c0 118-21 139-68 139-124 0-424-454-603-974C617 41 582 0 489 0H124C20 0-1 49-1 103c0 97 124 576 576 1209 301 433 726 667 1112 667 232 0 260-52 260-142v-327c0-104 22-125 95-125 54 0 147 27 363 236 247 247 288 358 427 358h365c104 0 156-52 126-155-33-102-151-251-308-427-85-100-212-209-251-263-54-70-39-100 0-162 0 0 444-626 491-838z"/></svg>*/}
                                    {/*</a>*/}
                                    <a href="https://instagram.com/on_mobile.ru" target="_blank" className={styles.icon}>
                                        <svg fill="#fff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                              viewBox="0 0 169.063 169.063"
                                             xmlSpace="preserve">
<g>
	<path d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752
		c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407
		c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752
		c17.455,0,31.656,14.201,31.656,31.655V122.407z"/>
    <path d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561
		C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561
		c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"/>
    <path d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78
		c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78
		C135.661,29.421,132.821,28.251,129.921,28.251z"/>
</g>
</svg>
                                    </a>
                                    <a href="https://t.me/on_mobile_ru" target="_blank" className={styles.icon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><path d="M88,134.9,177.9,214a8,8,0,0,0,13.1-4.2L228.6,45.6a8,8,0,0,0-10.7-9.2L33.3,108.9c-7.4,2.9-6.4,13.7,1.4,15.3Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/><line x1="88" y1="134.9" x2="224.1" y2="36.6" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/><path d="M132.9,174.4l-31.2,31.2A8,8,0,0,1,88,200V134.9" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/></svg>
                                    </a>
                                </div>

                            </div>

                            <div className={styles.desc}>
                                Сайт носит сугубо информационный характер и не является публичной офертой, определяемой Статьей 437 (2) ГК РФ. Apple и логотип Apple являются зарегистрированными товарными знаками компании Apple Inc. в США и других странах. App Store является знаком обслуживания компании Apple Inc.
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default Footer;