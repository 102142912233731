import React from "react";
import styles from '../scss/Accessories.module.scss';
import LoveAccessories from "../components/LoveAccessories";
import HomeCategories from "../components/HomeCategories";
import dyson from "../assets/images/dyson.avif";
import ContactForm from "../components/ContactForm";

function Accessories() {

    return (

        <>
            <LoveAccessories/>
            <HomeCategories/>

                <div className="wrapper">
                    <div className="container">
                        <div className={styles.section}>
                            <div className={styles.text}>
                                <div>
                                    <img src={dyson}/>
                                    <p>Премиальный бренд техники для красоты и дома. Высокое качество, лаконичный дизайн и передовые технологии — основные особенности техники Dyson.</p>
                                    <button className={styles.button}><span>Подробнее <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span></button>

                                </div>
                            </div>
                            <div className={styles.image}>
                                {/*<img src={image}/>*/}
                                <div className={styles.bg}></div>
                            </div>
                        </div>
                    </div>
                </div>

            <ContactForm/>
        </>


    );
}

export default Accessories;
