import React from "react";
import styles from '../scss/About.module.scss';
import about_image from '../assets/images/33986928_300_q55.avif';
import {Link} from "react-router-dom";

function About() {
    return (
        <div className="wrapper full-background" style={{background: "#000"}}>
            <div className="container" style={{background: "#000"}}>
                <div className={styles.section}/>
                <h4 className={styles.title}>Больше, чем просто компания.</h4>
                <div className={styles.about}>
                    <div className={styles.img}>
                        <img src={about_image}/>
                    </div>


                    <div>
                        <p className={styles.desc}>
                            Уже много лет наша команда занимается продажей новых и Б.У оригинальных гаджетов из Дубая! Мы сделаем всё необходимое для того, чтобы стать вашим надежным партнёром в мире мобильных девайсов!
                        </p>

                        <div className={styles.about_button}>
                            <Link to="/about">
                                <button className={styles.button}>
                                    <span>О компании</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg>
                                </button>
                            </Link>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default About;