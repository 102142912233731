import styles from '../scss/Credit.module.scss';
import Faq from "../components/Faq";
import CreditCalc from "../components/CreditCalc";
import CreditCalc2 from "../components/CreditCalc2";
import SwitchCalc from "../components/SwitchCalc";
import CreditForm from "../components/CreditForm";

function Credit() {
    return (
        <div className="wrapper">
            <div className="container">
                <div className={styles.section}>
                    <div className={styles.credit}>
                        <h4>Кредитование физических лиц</h4>
                        <div className={styles.items}>
                            <div className={styles.item}>
                                <h4>Кредитная программа</h4>
                                <ul>
                                    <li>Первоначальный взнос: от 20%</li>
                                    <li>Срок кредитования: до 24 месяцев</li>
                                    <li>Переплата по кредиту: 9.5%</li>
                                    <li>Досрочное погашение: Возможно</li>
                                    <li>Снижение переплаты прямо пропорционально снижению общего срока кредитования</li>
                                </ul>
                            </div>
                            <div className={styles.item}>
                                <h4>Условия выдачи</h4>
                                <ul>
                                    <li>Возраcт заёмщика не менее 18 лет</li>
                                    <li>Заёмщик предоставляет копию действующего (проверяем по базе ФМС: services.fms.gov.ru) паспорта РФ, Украины, Казахстана, Узбекистана, Кыргызстана, Белоруссии</li>
                                    <li>Заёмщик предоставляет копию Второго документа удостоверяющего личность (В/У, Полис ОМС, Заграничный Паспорт, Военный билет)</li>
                                    <li>Продающая сторона составляет договор кредитования, заёмщик принимает условия кредитования и обязуется исполнять их</li>
                                </ul>
                            </div>
                            <div className={styles.item}>
                                <h4>Процесс оформления кредита</h4>
                                <ul>
                                    <li>Интернет магазин составляет действующий договор кредитования. </li>
                                    <li>Покупатель производит оплату первоначального взноса на расчётный счёт продавца. </li>
                                    <li>Представитель интернет-магазина высылает товар Покупателю любой транспортной компанией на усмотрение покупателя. </li>
                                    <li>Курьер передаёт покупателю товар, договор кредитования, путевую документацию. </li>
                                    <li>При получении Покупатель предоставляет курьеру оригинал паспорта для ознакомления, оригинал второго документа удостоверяющего личность для ознакомления, копию паспорта, копию второго документа удостоверяющего личность. </li>
                                    <li>Курьер передаёт покупателю оригинал договора кредитования (2 Экз.), подписанный стороной продавца. </li>
                                    <li>Покупатель ознакамливается с договором, расписывается в графе «Покупатель». </li>
                                    <li>Сделка заключена: покупатель забирает свой товар у курьера. </li>
                                    <li>Стороной-посредником, которая предоставляет для покупателя кредит является Альфа Банк, основным требованием к заёмщику является отсутствие судебных делопроизводств. </li>
                                    <li>Полное и частичное досрочное погашение кредита возможно уже в первый месяц пользования кредитом и без ограничения по минимальной сумме для частичного досрочного погашения.</li>
                                    <li>Чтобы узнать сумму, требуемую для досрочного погашения кредита, необходимо обратиться к менеджеру по продажам.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <SwitchCalc/>
                    <CreditForm/>
                    <div className={styles.faq}>
                        {/*<div className={styles.accordion}>*/}
                        {/*    {accordionData.map(({ title, content }) => (*/}
                        {/*        <Accordion title={title} content={content} />*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                        <h4>Ответы на вопросы</h4>
                        <Faq/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Credit