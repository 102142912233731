import React from "react";
import styles from '../scss/AboutPage.module.scss';
import image from "../assets/images/signa.png";
import ContactForm from "../components/ContactForm";
import image1 from "../assets/images/p1.png";
import image2 from "../assets/images/p2.png";
import image3 from "../assets/images/p3.png";
import { Slide } from 'react-slideshow-image'

const images = [require("../assets/images/Sldier/slaid_5.webp"), require("../assets/images/Sldier/slaid_3.webp")];

function About() {

    return (

        <>
            <div className="wrapper" style={{background: "rgb(242, 242, 242)"}}>
                <div className="container">
                    <div className={styles.section} style={{flexDirection: "column"}}>
                        <div style={{display: "flex"}} className={styles.fixcol}>
                            <div className={styles.image}>
                                {/*<img src={image}/>*/}
                                <div className={styles.bg}></div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.text_section}>
                                    <p>
                                        Коллектив нашего магазина более 7 лет занимается логистикой и транзитом товаров из ОАЭ. Основным направлением нашей деятельности является реализация товаров продукции Apple, Samsung на территории Российской Федерации. Мы годами выстраивали надёжную логистическую цепочку отношений с нашими зарубежными поставщиками. Руководство магазина готово принять на себя полную материальную ответственность за транзит вашего груза. Мы всегда проверяем представленный к продаже товар на полное соответствие требованиям регламента ГОСТ Р.
                                    </p>
                                    {/*<button className={styles.button}><span>Подробнее <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span></button>*/}
                                    <div className={styles.signature}>
                                        <div className={styles.signature_name}>
                                            <p>Генеральный директор Барышев Александр Анатольевич</p>
                                            <a href="https://instagram.com/on_mobile.ru" target="_blank">
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg> Instagram</span>

                                            </a>
                                        </div>
                                        <div className={styles.signature_image}><img src={image}/></div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <p style={{padding: "30px"}}>Сегодня наш магазин является одним из самых узнаваемых магазинов Apple. Мы регулярно пополняем свой ассортимент новыми премиальными брендами техники DJI, Sony, Harman&Kardon и аксессуарами. В магазине вы можете узнать всё необходимое о об устройствах из нашего ассортимента. Наши сотрудники в магазине и на доставках профессионалы в своей области, готовы всегда подсказать и ответить на все вопросы. Они помогут настроить и подготовить ваше новое устройство к работе: наклеят защитное стекло, создадут учётную запись, перенесут данные, установят необходимые программы и настроят почтовый клиент.

                            Мы делаем всё для того, чтобы вам было комфортно совершать покупки и гордимся тем, что нас продолжают выбирать сотни тысяч клиентов по всей России. </p>
                    </div>
                    {/*<div className={styles.sale}>*/}
                    {/*    <h4>SALE FOR SAVE</h4>*/}
                    {/*    <div>*/}
                    {/*        <h5>SALE FOR SAVE</h5>*/}
                    {/*        <p>Процент от каждой продажи отправляется в благотворительные фонды по всей России.*/}
                    {/*            Детские дома, волонтерские организации, приюты для животных. Совершая покупку в нашем магазине — вы автоматически участвуете в благотворительной акции.</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={styles.about_slider}>
                        <Slide transitionDuration={250}>
                            {
                                images.map((item,index) => {
                                    return (
                                        <div key={item + index} className="each-slide-effect slider-about">
                                            <div style={{ 'backgroundImage': `url(${item})` , backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
                                            </div>
                                        </div>
                                    );
                                })}
                        </Slide>
                    </div>
                    <div className={styles.service}>
                        <div className={styles.benefits}>
                            <div className={styles.item}>
                                <h4>Регистрационная информация</h4>
                                <ul>
                                    <li>ООО "ПЛАЗА-ОПТ"</li>
                                    <li>ОГРН 1155658033387</li>
                                    <li>ИНН 5603043557</li>
                                    <li>КПП 560301001</li>
                                    <li>Генеральный директор Барышев Александр Анатольевич</li>
                                </ul>
                            </div>
                            <div className={styles.item}>
                                <h4>Публичные документы</h4>
                                <ul>
                                    <li><a href="check3.jpg" target="_blank">Счет на оплату</a></li>
                                    <li><a href="TAX%20Invoice.pdf" target="_blank">Tax Invoice</a></li>
                                    <li><a href="egrul.pdf" target="_blank">Выписка из ЕГРЮЛ</a></li>
                                    <li><a href="dog.pdf" target="_blank">Договор купли-продажи и поставки товара</a></li>
                                    <li><a href="ustav3.pdf" target="_blank">Устав</a></li>
                                </ul>
                            </div>

                        </div>
                        <div className={styles.start}>
                            <div className={styles.start_section}>
                                <span>Работаем для вас <strong>с 2015 года.</strong></span>

                                <div className={styles.items}>
                                    <img className={styles.image} src={image1}/>
                                    <img className={styles.image} src={image2}/>
                                    <img className={styles.image} src={image3}/>
                                </div>

                                <div className={styles.benefits}>
                                <div className={styles.item}>
                                    <h4>ГОСТ Р СЕРТИФИКАТЫ СООТВЕТСТВИЯ</h4>
                                    <ul>
                                        <li>ГОСТ Р Сертификат (Apple) №25ВА 2388283 от 24.07.2017 </li>
                                        <li>ГОСТ Р Сертфикат (Samsung) №25ВА 2388235 от 15.10.2017</li>
                                        <li>ГОСТ Р Сертификат (Xaomi) №25ВА 2388321 от 15.10.2017 </li>
                                        <li>ГОСТ Р Сертификат (JBL) №25ВА 2388549 от 18.10.2017 </li>
                                    </ul>
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ContactForm/>
        </>


    );
}

export default About;
