import React from "react";
import styles from '../scss/BenefitsProduct.module.scss';

function BenefitsProduct() {
    return (
        <div className="wrapper">
            <div className="container">
                <div className={styles.section}>
                    <div className={styles.benefits}>
                        <div className={styles.item}>
                            <div className={styles.title}>
                                <div className={styles.image}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13 2h-2.052c-.23-1.142-1.24-2-2.45-2h-1a2.5 2.5 0 0 0-2.45 2H3a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM7.5 1h1c.65 0 1.19.42 1.397 1H6.1c.207-.58.748-1 1.4-1zM13 14.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V13h10v1.5zm0-2.5H3V3.5a.5.5 0 0 1 .5-.5H5v3h1V3h4v3h1V3h1.5a.5.5 0 0 1 .5.5V12z"></path></svg>
                                </div>
                                <h4>
                                    Оригинальная техника.
                                </h4>
                            </div>
                            <p>Мы продаем только новую и оригинальную технику Apple уже более 10 лет. Гарантия 1 год с момента активации.</p>
                        </div>

                        <div className={styles.item}>
                            <div className={styles.title}>
                                <div className={styles.image}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M15.838 8.19L15.8 8 15 4a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3l-.838 4.19A1.996 1.996 0 0 0 1 10v5h1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1h8v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1h1v-5c0-.805-.478-1.494-1.162-1.81zM4 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2l.8 4H3.2L4 4zm0 11.5a.5.5 0 0 1-1 0V15h1v.5zm11 0a.5.5 0 0 1-1 0V15h1v.5zm1-2.5v1.002H2v-1V10a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3zm-2.5-3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm0 2a.5.5 0 1 1 .002-1.002A.5.5 0 0 1 13.5 12zm-9-2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm0 2a.5.5 0 1 1 .002-1.002A.5.5 0 0 1 4.5 12z"></path></svg>

                                </div>
                                <h4>
                                    Оригинальная техника.
                                </h4>
                            </div>
                            <p>Мы продаем только новую и оригинальную технику Apple уже более 10 лет. Гарантия 1 год с момента активации.</p>
                        </div>

                        <div className={styles.item}>
                            <div className={styles.title}>
                                <div className={styles.image}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M4 14v-2H3a1.99 1.99 0 0 1-1.773-1.093A.49.49 0 0 1 1 10.5V3.777c-.61.55-1 1.336-1 2.22v4a3 3 0 0 0 3 3v3l3.75-3H9v-1H6.666L4 14zm9-14H5a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h4.25L13 13v-3a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3zm2 7a2 2 0 0 1-2 2h-1v2L9.33 9H5a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v4z"></path></svg>
                                 </div>
                                <h4>
                                    Оригинальная техника.
                                </h4>
                            </div>
                            <p>Мы продаем только новую и оригинальную технику Apple уже более 10 лет. Гарантия 1 год с момента активации.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BenefitsProduct;