import React from 'react';
import styles from '../scss/Credit.module.scss';

function CreditCalc2() {

    const [sliderValue, setSliderValue] = React.useState(50); // Initial value of the slider
    const [sliderValueMonth, setSliderValueMonth] = React.useState(5); // Initial value of the slider
    const [sliderValueStartMoney, setSliderValueStartMoney] = React.useState(30); // Initial value of the slider

    const minRange = 1; // Minimum value for the range slider
    const maxRange = 200000; // Maximum value for the range slider

    const minRangeMonth = 1; // Minimum value for the range slider
    const maxRangeMonth = 24; // Maximum value for the range slider

    const minRangeStartMoney= 20; // Minimum value for the range slider
    const maxRangeStartMoney = 100; // Maximum value for the range slider


    const handleSliderChange = (event) => {
        setSliderValue(parseInt(event.target.value));
    };

    const handleSliderChangeMonth = (event) => {
        setSliderValueMonth(parseInt(event.target.value));
    };

    const handleSliderChangeStartMoney = (event) => {
        setSliderValueStartMoney(parseInt(event.target.value));
    };

    const handleInputChange = (event) => {
        let value = event.target.value;

        // Remove leading zeros
        value = value.replace(/^0+/, '');

        // Validate if the value is a number
        const intValue = parseInt(value);
        const isValid = !isNaN(intValue);

        // Check if the value is within the minRange and maxRange
        if (isValid && intValue < minRange) {
            value = minRange.toString();
        } else if (isValid && intValue > maxRange) {
            value = maxRange.toString();
        }

        setSliderValue(value === '' ? minRange : parseInt(value));
    };


    const handleInputChangeMonth = (event) => {
        let value = event.target.value;

        // Remove leading zeros
        value = value.replace(/^0+/, '');

        // Validate if the value is a number
        const intValue = parseInt(value);
        const isValid = !isNaN(intValue);

        // Check if the value is within the minRange and maxRange
        if (isValid && intValue < minRangeMonth) {
            value = minRangeMonth.toString();
        } else if (isValid && intValue > maxRangeMonth) {
            value = maxRangeMonth.toString();
        }

        setSliderValueMonth(value === '' ? minRangeMonth : parseInt(value));
    };

    const handleInputChangeStartMoney = (event) => {
        let value = event.target.value;

        // Remove leading zeros
        value = value.replace(/^0+/, '');

        // Validate if the value is a number
        const intValue = parseInt(value);
        const isValid = !isNaN(intValue);

        // Check if the value is within the minRange and maxRange
        if (isValid && intValue < minRangeStartMoney) {
            value = minRangeStartMoney.toString();
        } else if (isValid && intValue > maxRangeStartMoney) {
            value = maxRangeStartMoney.toString();
        }

        setSliderValueStartMoney(value === '' ? minRangeStartMoney : parseInt(value));
    };

    const body_value = sliderValue - ((sliderValueStartMoney / 100) * sliderValue)
    const value3 = body_value * 0.095

    const value1 = (body_value + value3) / sliderValueMonth


    // Первоначальный взнос
    const value2 = (sliderValueStartMoney / 100) * sliderValue




    return (
        <div className="wrapper">
            <div className="container">
                <div className={styles.section} style={{paddingTop: "80px"}}>
                    <div className={styles.credit}>
                        {/*<h4>Калькулятор рассрочки и кредита</h4>*/}
                        <div className={styles.items}>
                            <div className={styles.item_calc}>
                                <h4>Калькулятор кредита</h4>
                                <div className={styles.slider_container}>
                                    <input
                                        type="range"
                                        min={minRange}
                                        max={maxRange}
                                        step="1"
                                        value={sliderValue}
                                        onChange={handleSliderChange}
                                        className={styles.slider}
                                    />
                                    <div className={styles.slider_box}>
                                        <input
                                            type="text"
                                            min={minRange}
                                            max={maxRange}
                                            value={sliderValue}
                                            onChange={handleInputChange}
                                            className={styles.slider_input}
                                            pattern="^[1-9][0-9]*$"
                                            style={{border: "unset", outline: "none"}}
                                        />
                                        <p className={styles.slider_tag}>руб.</p>
                                    </div>

                                    <p className={styles.slider_title}>Стоимость покупки</p>
                                </div>
                                <div className={styles.slider_container}>
                                    <input
                                        type="range"
                                        min={minRangeMonth}
                                        max={maxRangeMonth}
                                        step="1"
                                        value={sliderValueMonth}
                                        onChange={handleSliderChangeMonth}
                                        className={styles.slider}
                                    />
                                    <div className={styles.slider_box}>
                                        <input
                                            type="text"
                                            min={minRangeMonth}
                                            max={maxRangeMonth}
                                            value={sliderValueMonth}
                                            onChange={handleInputChangeMonth}
                                            className={styles.slider_input}
                                            pattern="^[1-9][0-9]*$"
                                            style={{border: "unset", outline: "none"}}
                                        />
                                        <p className={styles.slider_tag}>мес.</p>
                                    </div>

                                    <p className={styles.slider_title}>Срок предоставления</p>
                                </div>
                                <div className={styles.slider_container}>
                                    <input
                                        type="range"
                                        min={minRangeStartMoney}
                                        max={maxRangeStartMoney}
                                        step="1"
                                        value={sliderValueStartMoney}
                                        onChange={handleSliderChangeStartMoney}
                                        className={styles.slider}
                                    />
                                    <div className={styles.slider_box}>
                                        <input
                                            type="text"
                                            min={minRangeStartMoney}
                                            max={maxRangeStartMoney}
                                            value={sliderValueStartMoney}
                                            onChange={handleInputChangeMonth}
                                            className={styles.slider_input}
                                            pattern="^[1-9][0-9]*$"
                                            style={{border: "unset", outline: "none"}}
                                        />
                                        <p className={styles.slider_tag}>%</p>
                                    </div>

                                    <p className={styles.slider_title}>Первоначальный взнос</p>
                                </div>
                                <div className={styles.slider_info}>
                                    <p>Ежемесячный платеж: <span>{value1.toFixed(0)}</span> руб.</p>
                                    <p>Первоначальный взнос: <span>{value2.toFixed(0)}</span> руб.</p>
                                    <p>Переплата: <span>{value3.toFixed(0)}</span> руб.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreditCalc2