import React from "react";
import styles from "../scss/Order.module.scss";
import {addItem, minusItem, removeItem} from "../redux/slices/orderSlice";
import {useDispatch, useSelector} from "react-redux";
import {data} from '../products.jsx';

function OrderProduct(
    {id, name, desc, price, options, isActiveMemory, isActiveColor, isActiveGen, isActiveSize, isActiveNet, isActiveRam, isActiveMethod, generation_price, memory_price, size_price, net_price, ram_price}
    ) {
    const cartItem = useSelector((state) => state.order.items.find((obj) => obj.id === id
        && obj.isActiveMemory === isActiveMemory
        && obj.isActiveColor === isActiveColor
        && obj.isActiveGen === isActiveGen
        && obj.isActiveSize === isActiveSize
        && obj.isActiveNet === isActiveNet
        && obj.isActiveRam === isActiveRam
        && obj.isActiveMethod === isActiveMethod
    ));
    const count = cartItem ? cartItem.count : 0;

    const findItem = data.find(obj => obj.id === id)

    const dispatch = useDispatch();

    const onClickPlus = () => {
        dispatch(addItem({
            id,
            isActiveMemory,
            isActiveColor,
            isActiveGen,
            isActiveSize,
            isActiveNet,
            isActiveRam,
            isActiveMethod,
            generation_price,
            memory_price,
            size_price,
            net_price,
            ram_price,
        }))
    }
    const onClickMinus = () => {
        dispatch(minusItem({id, isActiveMemory, isActiveColor, isActiveGen, isActiveSize, isActiveNet, isActiveRam, isActiveMethod, price, memory_price, generation_price, size_price, net_price, ram_price}))
    }

    const onClickRemove = () => {
        dispatch(removeItem({id, isActiveMemory, isActiveColor, isActiveGen, isActiveSize, isActiveNet, isActiveRam, isActiveMethod, price, memory_price, generation_price, size_price, net_price, ram_price}))
    }

    return (
        <div className={styles.product}>
            <div className={styles.image}>
                {
                    findItem.options[0].color === 'disabled' ? (
                            <img src={findItem.options[0].images[0]}/>

                    ) : (
                        findItem.options[0].color ? (
                            <img src={findItem.options[0].color[isActiveColor].images[0]}/>
                        ) : (
                            <img src={findItem.options[0].images[0]}/>
                        )
                    )
                }
                {/*<img src={findItem.options[0].color[isActiveColor].images[0]}/>*/}
            </div>
            <div className={styles.info}>
                <div className={styles.title}>
                    <h4>{name}</h4>
                    <span onClick={onClickRemove}><svg viewBox="0 0 10 10"><path d="M5 4L1 0L0 1L4 5L0 9L1 10L5 6L9 10L10 9L6 5L10 1L9 0L5 4Z"></path></svg></span>
                </div>

                <p>
                    {
                        findItem.options[0].ram && (
                            findItem.options[0].ram[isActiveRam].value + ", "
                        )
                    }

                    {
                        findItem.options[0].memory && (
                            findItem.options[0].memory[isActiveMemory].value + ", "
                        )
                    }

                    {
                        findItem.options[0].net && (
                            findItem.options[0].net[isActiveNet].value + ", "
                        )
                    }

                    {
                        findItem.options[0].color && (
                            findItem.options[0].color[isActiveColor].title
                        )
                    }

                    {
                        findItem.options[0].size && (
                            ", " + findItem.options[0].size[isActiveSize].value
                        )
                    }

                    {
                        findItem.options[0].generation && (
                            findItem.options[0].generation[isActiveGen].value
                        )
                    }
                </p>
                {/*<p>{findItem.options[0].memory[isActiveMemory].value}, {findItem.options[0].color[isActiveColor].title}</p>*/}
                <div className={styles.options}>
                    <div className={styles.count}>
                        <div className={styles.increase} onClick={onClickMinus}>
                            <span></span>
                        </div>
                        <p>{count}</p>
                        <div className={styles.decrease} onClick={onClickPlus}><span></span><span></span></div>
                    </div>
                    <div className={styles.price}>
                        <div className={styles.item_price}>

                            {
                                isActiveMethod === 0 && (
                                    <span>{(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 20).toFixed(0)} р. / шт.</span>
                                )
                            }

                            {
                                isActiveMethod === 1 && (
                                    <span>{(((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 85).toFixed(0)} р. / шт.</span>
                                )
                            }

                            {
                                isActiveMethod === 2 && (
                                    <span>{(price + memory_price + generation_price + size_price + net_price + ram_price)} р. / шт.</span>
                                )
                            }

                            {/*{(price + memory_price + generation_price + size_price + net_price + ram_price)} р. / шт.*/}
                        </div>
                        <div className={styles.item_sum}>
                            {/*{(price + memory_price + generation_price + size_price + net_price + ram_price) * count} р.*/}

                            {
                                isActiveMethod === 0 && (
                                    <span>{count * (((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 20).toFixed(0)} р.</span>
                                )
                            }

                            {
                                isActiveMethod === 1 && (
                                    <span>{count * (((price + memory_price + generation_price + size_price + net_price + ram_price) / 100) * 85).toFixed(0)} р.</span>
                                )
                            }

                            {
                                isActiveMethod === 2 && (
                                    <span>{count * (price + memory_price + generation_price + size_price + net_price + ram_price)} р.</span>
                                )
                            }

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default OrderProduct