import React from 'react';
import styles from '../scss/Credit.module.scss';

const methods = [
    {"id": 1, "value":"Наложенный платёж", "img": require('../assets/images/cod.svg').default},
    {"id": 2, "value":"Скидка 15%", "img": require('../assets/images/pric.svg').default},
    {"id": 3, "value":"TAX-Free возврат 22% ", "img": require('../assets/images/retu.svg').default},
]

function TaxFreeCalc() {

    const [isActiveMethod, setActiveMethod] = React.useState(0);
    const [isValue, setValue] = React.useState(75592)

    const clickMethod = (size, index) => {
        setActiveMethod(index);
    }

    const handleInputChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <div className="wrapper">
            <div className="container">
                <div className={styles.section} style={{paddingTop: "80px"}}>
                    <div className={styles.credit}>
                        {/*<h4>Калькулятор рассрочки и кредита</h4>*/}
                        <div className={styles.items}>
                            <div className={styles.item_calc}>
                                <h4>Калькулятор TAXFREE</h4>
                                <div className={styles.tfdata}>
                                    <p>Введите сумму покупки</p>
                                    <input type="text" className={styles.tfinput} value={isValue} onChange={handleInputChange}/>
                                </div>
                                <div className={styles.tfoplata}>
                                    <p>Выберите способ оплаты</p>
                                    <ul className={styles.p_memory_ul}>
                                        {
                                            methods.map((item,index) => {
                                                return (
                                                    <li key={index}
                                                        onClick={() => clickMethod(item.value, index)}
                                                        className={isActiveMethod === index ? styles.active : ''}>
                                                        <img src={item.img}/>
                                                        {item.value}
                                                    </li>
                                                );
                                            })
                                        }

                                    </ul>
                                </div>
                                <div className={styles.slider_info}>
                                    <div className={styles.payment}>
                                        {
                                            isActiveMethod === 0 && (
                                                <p>Предоплата {((isValue / 100) * 20).toFixed(0)} р. (20%), при получении платится {((isValue / 100) * 80).toFixed(0)} р. (80%)</p>
                                            )
                                        }

                                        {
                                            isActiveMethod === 1 && (
                                                <p>Цена со скидкой: {((isValue / 100) * 85).toFixed(0)} р. (-15%), полная предоплата</p>
                                            )
                                        }

                                        {
                                            isActiveMethod === 2 && (
                                                <p>Возврат: {((isValue / 100) * 22).toFixed(0)} р. (22%), полная предоплата</p>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TaxFreeCalc