import React from "react";
import styles from '../scss/Iphone.module.scss';
import Feedback from "../components/Feedback";
import BenefitsProduct from "../components/BenefitsProduct";
import Cart from "../components/Cart";
import {data} from '../products.jsx';
import Product from "../components/Product";
import {useLocation} from "react-router-dom";

function ProductRouter() {
    const getProductURL = useLocation().pathname.slice(1);

    return (
        <>
            <Cart/>
            <div style={{background: "#f5f5f7"}}>
                <div className="wrapper" style={{background: "#f5f5f7"}}>
                    <div className="container">
                        <div className={styles.product_section}>
                            <div className={styles.title_info}>
                                <div className={styles.title_svg}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 22 22" id="icon"><path d="M8 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM4 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9-5H3a3 3 0 0 0-3 3v6.5C0 11.154 1.34 12 3 12h6l4 4v-4c1.655 0 3-.845 3-2.5V3a3 3 0 0 0-3-3zm2 9.5c0 1.104-.897 1.5-2 1.5h-1v2.5L9.5 11H3c-1.106 0-2-.396-2-1.5V3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v6.5zM12 5a1 1 0 1 0-.002 1.998 1 1 0 0 0 0-2z"/></svg>
                                </div>
                                <h4>Уважаемые клиенты, в связи с нестабильным курсом валют, цены на сайте могут быть не актуальны.</h4>
                            </div>
                            <div className={styles.product_items}>
                                {data
                                    .filter((obj) => obj.product === getProductURL)
                                    .sort((a, b) => b.id - a.id) // Сортировка по убыванию id
                                    .map((obj) => (
                                        <Product key={obj.id} {...obj}/>
                                    ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{background: "#fff"}}>
                <BenefitsProduct/>
                <Feedback/>
            </div>
        </>
    );
}

export default ProductRouter;
