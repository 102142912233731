import React from "react";
import BenefitsDelivery from "../components/BenefitsDelivery";
//import styles from '../scss/BenefitsDelivery.module.scss';


function Delivery() {
    return (
        <>
            <BenefitsDelivery/>
        </>

    );
}

export default Delivery;