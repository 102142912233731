import React from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import styles from '../scss/Header.module.scss';
import logo from '../assets/images/logo.png';
import {open, close, openList, openCatalog} from "../redux/slices/menuSlice";

function Header() {
    const isVisible = useSelector((state) => state.menu.value);


    // const dropdown = useSelector((state) => state.menu.dropdown);
    const isVisibleList = useSelector((state) => state.menu.dropdown);
    const isVisibleCatalog = useSelector((state) => state.menu.catalog);
    const dispatch = useDispatch();

    const dropdownRef = React.useRef();
    const catalogRef = React.useRef();

    const rotate = {
        transform: isVisibleList ? 'rotate(180deg)' : '',
        transition: 'transform 150ms ease',
    }

    const rotateCatalog = {
        transform: isVisibleCatalog ? 'rotate(180deg)' : '',
            transition: 'transform 150ms ease',
    }

    const decoration = {
        textDecorationLine: isVisibleList ? 'underline' : 'none',
        textUnderlineOffset: isVisibleList ? '8px' : ''
    }

    const decorationCatalog = {
        textDecorationLine: isVisibleCatalog ? 'underline' : 'none',
        textUnderlineOffset: isVisibleCatalog ? '8px' : ''
    }

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            let path = event.composedPath().includes(dropdownRef.current);
            if (!path) dispatch(openList(false));
        };

        document.body.addEventListener('click', handleClickOutside);

        return () => document.body.removeEventListener('click', handleClickOutside);
    }, [])

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            let path = event.composedPath().includes(catalogRef.current);
            if (!path) dispatch(openCatalog(false));
        };

        document.body.addEventListener('click', handleClickOutside);

        return () => document.body.removeEventListener('click', handleClickOutside);
    }, [])

    return (
        <header className={styles.header}>
                    <div onClick={() => dispatch(open())} className={styles.burger}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 22 22" id="icon"><path d="M1.5 2.5h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0 0 1zm0 4h9a.5.5 0 0 0 0-1h-9a.5.5 0 0 0 0 1zm7.5 7H1.5a.5.5 0 0 0 0 1H9a.5.5 0 0 0 0-1zm5.5-4h-13a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1z"/></svg>
                    </div>

                    {isVisible && (
                        <div className={styles.mobile_menu}>
                            <div onClick={() => dispatch(close())} className={styles.close}>
                                <svg width="50" height="50" viewBox="0 0 50 50" id="icon" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.5357 25L45.768 7.76777L42.2324 4.23224L25.0002 21.4645L7.76796 4.23224L4.23242 7.76777L21.4647 25L4.23242 42.2322L7.76796 45.7678L25.0002 28.5355L42.2324 45.7678L45.768 42.2322L28.5357 25Z" />
                                </svg>
                            </div>
                            <div className={styles.mobile_container}>
                                <div className={styles.mobile_items}>
                                    <Link to="/iphone" onClick={() => dispatch(close())} className={styles.mobile_item}>
                                        <p>iPhone</p>
                                    </Link>
                                    <Link to="/airpods" onClick={() => dispatch(close())} className={styles.mobile_item}>
                                        <p>AirPods</p>
                                    </Link>
                                    <Link to="/watch" onClick={() => dispatch(close())} className={styles.mobile_item}>
                                        <p>Watch</p>
                                    </Link>
                                    <Link to="/mac" onClick={() => dispatch(close())} className={styles.mobile_item}>
                                        <p>Mac</p>
                                    </Link>
                                    <Link to="/ipad" onClick={() => dispatch(close())} className={styles.mobile_item}>
                                        <p>iPad</p>
                                    </Link>
                                    <Link to="/accessories" onClick={() => dispatch(close())} className={styles.mobile_item}>
                                        <p>Аксессуары</p>
                                    </Link>
                                </div>

                                <ul className={styles.mobile_ul}>
                                    <Link to="/service" onClick={() => dispatch(close())} className={styles.mobile_li}>
                                        Сервисный центр
                                    </Link>
                                    <Link to="/delivery" onClick={() => dispatch(close())} className={styles.mobile_li}>
                                        Доставка и оплата
                                    </Link>
                                    <Link to="/credit" onClick={() => dispatch(close())} className={styles.mobile_li}>
                                        Кредит / Рассрочка
                                    </Link>
                                    <Link to="/taxfree" onClick={() => dispatch(close())} className={styles.mobile_li}>
                                        TAX-FREE
                                    </Link>
                                    <Link to="/garant" onClick={() => dispatch(close())} className={styles.mobile_li}>
                                        Гарантии
                                    </Link>
                                    <Link to="/about" onClick={() => dispatch(close())} className={styles.mobile_li}>
                                        О компании
                                    </Link>
                                    <Link to="/contacts" onClick={() => dispatch(close())} className={styles.mobile_li}>
                                        Контакты
                                    </Link>
                                </ul>
                                <div className={styles.mobile_line}></div>

                                <div className={styles.mobile_links}>
                                    <a href="tel:+79014179922" className={styles.mobile_link}>
                                        +7 (901) 417-99-22
                                    </a>
                                    <a href="https://wa.me/79014179922" target="_blank" className={styles.mobile_link}>
                                        WhatsApp
                                    </a>
                                    <a href="https://instagram.com/on_mobile.ru" target="_blank" className={styles.mobile_link}>
                                        Instagram
                                    </a>
                                    <a href="https://t.me/on_mobile_ru" target="_blank" className={styles.mobile_link}>
                                        Telegram
                                    </a>
                                    {/*<a href="https://vk.com/onestore.shop" target="_blank" className={styles.mobile_link}>*/}
                                    {/*    VK*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </div>
                    )}


                        <Link to="/" className={styles.header_logo}>
                            <img src={logo}/>
                        </Link>

                    <ul className={styles.ul}>
                        <li ref={catalogRef} onClick={() => dispatch(openCatalog(!isVisibleCatalog))} style={decorationCatalog}>Каталог
                            <svg style={rotateCatalog} className="folder-arrow" viewBox="0 0 10 10"><path d="M5 6l4-4 1 1-5 5-5-5 1-1 4 4z"></path></svg>
                            {isVisibleCatalog && (
                                <ul className={styles.dropdown}>
                                    <Link to="/iphone"><li><span>iPhone</span></li></Link>
                                    <Link to="/airpods"><li><span>AirPods</span></li></Link>
                                    <Link to="/watch"><li><span>Watch</span></li></Link>
                                    <Link to="/ipad"><li><span>iPad</span></li></Link>
                                    <Link to="/mac"><li><span>Mac</span></li></Link>
                                    <Link to="/dyson"><li><span>Dyson</span></li></Link>
                                    <Link to="/accessories"><li><span>Аксессуары</span></li></Link>
                                </ul>
                            )}
                        </li>

                        <Link to="/credit"><li>Кредит / Рассрочка</li></Link>
                        <Link to="/taxfree"><li>TAX-FREE</li></Link>
                        <Link to="/service"><li>Сервис</li></Link>
                        <Link to="/garant"><li>Гарантии</li></Link>

                        <li ref={dropdownRef} onClick={() => dispatch(openList(!isVisibleList))} style={decoration}>О нас
                            <svg style={rotate} className="folder-arrow" viewBox="0 0 10 10"><path d="M5 6l4-4 1 1-5 5-5-5 1-1 4 4z"></path></svg>
                            {isVisibleList && (
                            <ul className={styles.dropdown}>
                                <Link to="/about"><li><span>О компании</span></li></Link>
                                <Link to="/delivery"><li><span>Доставка и оплата</span></li></Link>
                                <Link to="/contacts"><li><span>Контакты</span></li></Link>
                            </ul>
                                )}
                        </li>
                    </ul>

                    <a href="tel:+79014179922" className={styles.button}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.648.27c-.632-.44-1.326-.28-1.608.12-.172.245-1.953 2.915-2.153 3.242a.96.96 0 0 0-.13.506c0 .243.07.514.21.778.136.257.58 1.034.798 1.416-.236.335-.842 1.123-2.088 2.366-1.238 1.234-2.035 1.844-2.373 2.08a77.354 77.354 0 0 0-1.42-.794C4.4 9.73 3.916 9.7 3.59 9.907 3.282 10.1.624 11.87.37 12.053c-.238.172-.366.48-.366.814 0 .265.08.544.25.787.016.023 1.69 2.37 2.66 2.338 2.726-.086 5.93-2.728 8.137-4.927 2.208-2.2 4.857-5.394 4.944-8.124v-.02c0-.956-2.323-2.634-2.346-2.65zm-3.304 10.09C7.43 13.263 4.8 14.907 2.932 14.994c-.354-.126-1.26-1.04-1.89-1.91a.396.396 0 0 1-.066-.258c.408-.28 2.694-1.785 3.12-2.06a.74.74 0 0 1 .323.1c.174.092.68.376 1.388.778l.552.313.52-.363c.386-.27 1.227-.916 2.505-2.19 1.284-1.28 1.93-2.116 2.2-2.5l.364-.518-.316-.55a69.192 69.192 0 0 1-.78-1.386.7.7 0 0 1-.097-.313v-.013c.24-.382 1.79-2.71 2.074-3.12.04-.008.128 0 .236.074.88.633 1.796 1.53 1.928 1.882-.09 1.872-1.737 4.496-4.65 7.4z"/></svg>
                        <span>+7 (901) 417-99-22</span>
                    </a>
        </header>
    );
}

export default Header;