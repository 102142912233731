import React from "react";
import styles from '../scss/Cart.module.scss';
import Order from "./Order";
import {openOrder} from "../redux/slices/orderSlice";
import {useDispatch, useSelector} from "react-redux";

function Cart() {
    const isVisible = useSelector((state) => state.order.value);
    const {items} = useSelector((state) => state.order);

    const count = items.reduce((sum, item) => sum + item.count, 0);

    const dispatch = useDispatch();


    return (
      <>
          <div className={styles.cart_icon} onClick={() => dispatch(openOrder(!isVisible))}>
              <span>{count}</span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <path d="M14 2a4 4 0 0 0-4 4H7a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-3a4 4 0 0 0-4-4zm2 6v2a1 1 0 0 0 2 0V8h3v16H7V8h3v2a1 1 0 0 0 2 0V8h4zm0-2a2 2 0 0 0-4 0h4z"/>
              </svg>
          </div>

          {
              isVisible && (
                  <Order/>
              )
          }


      </>
    );
}

export default Cart;