import React from "react";
import styles from '../scss/HomeCategories.module.scss';
import image1 from '../assets/images/HomeCategories/33081605_74_q55.avif';
import image2 from '../assets/images/HomeCategories/33081647_74_q55.avif';
import image3 from '../assets/images/HomeCategories/33081651_74_q55.avif';
import image4 from '../assets/images/HomeCategories/33081645_74_q55.avif';
import image5 from '../assets/images/HomeCategories/33081642_74_q55.avif';

function HomeCategories() {
    return (
        <div className="wrapper">
            <div className="container">
                <div className={styles.section}>
                    <div>
                        <img src={image1}/>
                        <p>Кабели, адаптеры</p>
                    </div>
                    <div>
                        <img src={image2}/>
                        <p>Чехлы, MagSafe</p>
                    </div>
                    <div>
                        <img src={image3}/>
                        <p>Клавиатуры, мышки</p>
                    </div>
                    <div>
                        <img src={image4}/>
                        <p>Дроны (в разработке)</p>
                    </div>
                    <div>
                        <img src={image5}/>
                        <p>Приставки, игры (в разработке)</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeCategories;