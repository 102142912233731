import React from "react";
import styles from '../scss/LoveAccessories2.module.scss';
import image2 from '../assets/images/34202859_214_q55.avif';
import image1 from '../assets/images/34169623_222_q55.avif';
import image3 from '../assets/images/The-iPhone-15-front-and-back.png';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import FormEmail2 from "./FormEmail2";
import {openContactForm} from "../redux/slices/menuSlice";

function LoveAccessories2() {
    const isVisible = useSelector((state) => state.menu.contact);
    const dispatch = useDispatch();


    return (
        <>
            <div className="wrapper">
                <div className="container">
                    <div className={styles.section}>
                        <div className={styles.title}>
                            <div>
                                <p>
                                    Оригинальные гаджеты из Дубая
                                </p>
                                <h1>С возможностью оформить TAX-Free возврат 22% от суммы любой покупки</h1>
                            </div>
                            <div className={styles.faq} onClick={() => dispatch(openContactForm(!isVisible))}>
                            <span>Нужна помощь? Задать вопрос
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg>
                            </span>
                            </div>
                        </div>
                        <div className={styles.items}>

                            <Link to="/iphone" >
                                <div >
                                    <div className={styles.item} style={{alignItems: "end", justifyContent: "end"}}>
                                        <img src={image3} style={{height: 'auto'}}/>
                                    </div>
                                    <span>iPhone 15
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg>
                            </span>

                                </div>
                            </Link>

                            <Link to="/airpods" style={{alignItems: "center"}}>
                                <div >

                                    <div className={styles.item} style={{alignItems: "center"}}>
                                        <img src={image2} style={{height: '80%'}}/>
                                    </div>
                                    <span>AirPods
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg>
                            </span>

                                </div>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
            {
                isVisible && (
                    <FormEmail2/>
                )
            }
        </>

    );
}

export default LoveAccessories2;