import React from "react";
import styles from '../scss/ContactForm.module.scss';
import axios from "axios";
import * as yup from "yup";
import {CSSTransition} from "react-transition-group";

function ContactForm() {

    const [sendText, setText] = React.useState('Отправить');


    const ref = React.useRef();

    const [formData, setFormData] = React.useState({
        name: '',
        phone: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({...formData, [name]: value});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        validate();
        if (Object.keys(formErrors).length === 0) {
            axios.post('https://one-iphonestore.ru/api/send-form', formData)
                .then((response) => {
                    console.log(response);

                })
                .catch((error) => {
                    console.log(error);
                });

            setText('Отправка ...');
            setTimeout(() => {
                setText('Отправлено!');
                formData.name = '';
                formData.phone = '';
            }, 2000);

            setTimeout(() => {
                setText('Отправить');
            }, 10000);
        }
    }

    const validationSchema = yup.object().shape({
        name: yup.string().required('Обязательно *'),
        phone: yup.string().required('Обязательно *'),
    });
    const [formErrors, setFormErrors] = React.useState({});

    const validate = () => {
        validationSchema.validate(formData, { abortEarly: false })
            .then(() => {
                setFormErrors({});
            })
            .catch((error) => {
                const errors = error.inner.reduce((acc, cur) => {
                    return { ...acc, [cur.path]: cur.message };
                }, {});
                setFormErrors(errors);
            });
    }


    return (
            <div className="wrapper" style={{background: "#f2f2f2"}}>
                <div className="container">
                    <div className={styles.section}>
                        <div className={styles.text}>
                            <div>
                                <h4>Остались вопросы?</h4>
                                <p>Мы свяжемся с вами, ответим на все интересующие вас вопросы.</p>
                                <form className={styles.form} onSubmit={handleSubmit}>
                                    <div className={styles.form_group}>
                                        <label htmlFor="name">Ваше имя</label>
                                        <input type="text" name="name" className={styles.form_control} value={formData.name} onChange={handleInputChange} onBlur={validate}/>
                                        <CSSTransition in={formErrors.name != null} timeout={300} classNames="error-message" unmountOnExit>
                                            <div className="error-message">{formErrors.name}</div>
                                        </CSSTransition>
                                    </div>
                                    <div className={styles.form_group}>
                                        <label htmlFor="phone">Телефон *</label>
                                        <input type="text" name="phone" className={styles.form_control} value={formData.phone} onChange={handleInputChange} onBlur={validate}/>
                                        <CSSTransition in={formErrors.name != null} timeout={300} classNames="error-message" unmountOnExit>
                                            <div className="error-message">{formErrors.phone}</div>
                                        </CSSTransition>
                                    </div>
                                    <div className={styles.button}>
                                        <button type="submit" className={styles.send_button}>
                                            <span ref={ref}>{sendText} <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span>
                                        </button>
                                    </div>
                                </form>
                                <span>Нажимая на кнопку, вы даете согласие на обработку персональных данных</span>
                            </div>

                            <div className={styles.contact_side}>
                                <div className={styles.contact_block}>
                                    <p className={styles.contact_title}>Или напишите нам в мессенджерах:</p>
                                    <div className={styles.contact_list}>
                                        <a href="https://wa.me/79014179922" target="_blank" className={styles.contact_item}><svg fill="#fff" version="1.1" id="Layer_1"
                                                                                  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                                  viewBox="0 0 308 308" xmlSpace="preserve">
                                            <g id="XMLID_468_">
                                                <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                                        c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                                        c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                                        c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                                        c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                                        c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                                        c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                                        c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                                        c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                                        C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                                                <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                                        c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                                        c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                                         M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                                        l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                                        c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                                        C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                                            </g>
                                        </svg>WhatsApp</a>
                                        <a href="https://t.me/on_mobile_ru" target="_blank" className={styles.contact_item}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect fill="none"/><path d="M88,134.9,177.9,214a8,8,0,0,0,13.1-4.2L228.6,45.6a8,8,0,0,0-10.7-9.2L33.3,108.9c-7.4,2.9-6.4,13.7,1.4,15.3Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/><line x1="88" y1="134.9" x2="224.1" y2="36.6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/><path d="M132.9,174.4l-31.2,31.2A8,8,0,0,1,88,200V134.9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/></svg>
Telegram</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
    );
}

export default ContactForm;
