import { createSlice } from '@reduxjs/toolkit'
//import type { PayloadAction } from '@reduxjs/toolkit'


const initialState = {
    value: false,
    totalPrice: 0,
    deliveryCost: 0,
    finalSum: 0,
    items: JSON.parse(localStorage.getItem('cart')) || [],
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        openOrder: (state, {payload}) => {
            state.value = payload
        },
        addItem: (state, {payload}) => {
            const findItem = state.items.find(obj => obj.id === payload.id
                && obj.isActiveMemory === payload.isActiveMemory
                && obj.isActiveColor === payload.isActiveColor
                && obj.isActiveGen === payload.isActiveGen
                && obj.isActiveSize === payload.isActiveSize
                && obj.isActiveNet === payload.isActiveNet
                && obj.isActiveRam === payload.isActiveRam
                && obj.isActiveMethod === payload.isActiveMethod
            )

            if(findItem) {
                findItem.count++;
            }else{
                state.items.push({
                    ...payload,
                    count: 1
                });
            }

            state.totalPrice = state.items.reduce((sum, obj) => {
                let price = obj.price + obj.memory_price + obj.generation_price + obj.size_price + obj.net_price + obj.ram_price;

                if(obj.isActiveMethod === 0) {
                    price = ((price / 100) * 20).toFixed(0);
                }

                if(obj.isActiveMethod === 1) {
                    price = ((price / 100) * 85).toFixed(0);
                }

                return price * obj.count + sum;
            }, 0)
        },
        minusItem: (state, {payload}) => {
            const findItem = state.items.find(obj => obj.id === payload.id
                && obj.isActiveMemory === payload.isActiveMemory
                && obj.isActiveColor === payload.isActiveColor
                && obj.isActiveGen === payload.isActiveGen
                && obj.isActiveSize === payload.isActiveSize
                && obj.isActiveNet === payload.isActiveNet
                && obj.isActiveRam === payload.isActiveRam
                && obj.isActiveMethod === payload.isActiveMethod
            )

            if(findItem) {
                if(findItem.count <= 1) {
                    state.items = state.items.filter((obj) => obj.id !== payload.id
                        || obj.isActiveMemory !== payload.isActiveMemory
                        || obj.isActiveColor !== payload.isActiveColor
                        || obj.isActiveGen !== payload.isActiveGen
                        || obj.isActiveSize !== payload.isActiveSize
                        || obj.isActiveNet !== payload.isActiveNet
                        || obj.isActiveRam !== payload.isActiveRam
                        || obj.isActiveMethod !== payload.isActiveMethod
                    )
                }else{
                    findItem.count--;
                }

                let price = payload.price + payload.memory_price + payload.generation_price + payload.size_price + payload.net_price + payload.ram_price;

                if(payload.isActiveMethod === 0) {
                    price = ((price / 100) * 20).toFixed(0);
                }else
                if(payload.isActiveMethod === 1) {
                    price = ((price / 100) * 85).toFixed(0);
                }

                state.totalPrice -= price;
            }


            //state.totalPrice = state.totalPrice -  (findItem.price * findItem.count)

        },
        removeItem: (state, {payload}) => {
            const findItem = state.items.find(obj => obj.id === payload.id
                && obj.isActiveMemory === payload.isActiveMemory
                && obj.isActiveColor === payload.isActiveColor
                && obj.isActiveGen === payload.isActiveGen
                && obj.isActiveSize === payload.isActiveSize
                && obj.isActiveNet === payload.isActiveNet
                && obj.isActiveRam === payload.isActiveRam
                && obj.isActiveMethod === payload.isActiveMethod
            );

            //state.totalPrice -= findItem.price * findItem.count;


            let price = payload.price + payload.memory_price + payload.generation_price + payload.size_price + payload.net_price + payload.ram_price;

            if(payload.isActiveMethod === 0) {
                price = ((price / 100) * 20).toFixed(0);
            }else
            if(payload.isActiveMethod === 1) {
                price = ((price / 100) * 85).toFixed(0);
            }

            state.totalPrice = state.totalPrice - price * findItem.count
            state.items = state.items.filter((obj) => obj.id !== payload.id
                || obj.isActiveMemory !== payload.isActiveMemory
                || obj.isActiveColor !== payload.isActiveColor
                || obj.isActiveGen !== payload.isActiveGen
                || obj.isActiveSize !== payload.isActiveSize
                || obj.isActiveNet !== payload.isActiveNet
                || obj.isActiveRam !== payload.isActiveRam
                || obj.isActiveMethod !== payload.isActiveMethod
            )
        },
        setDelivery: (state, {payload}) => {
            state.deliveryCost = payload;
            state.finalSum = state.totalPrice + state.deliveryCost;

        },
        clearItems: (state, {paylaod}) => {
            state.items = [];
        }
    },
})

export const { openOrder, addItem, removeItem, minusItem, setDelivery, openContact, clearItems} = orderSlice.actions

export default orderSlice.reducer