import React from "react";
import styles from '../scss/BestCategories.module.scss';
import image1 from '../assets/images/BestCategories/34205583_78_q55.avif';
import image2 from '../assets/images/BestCategories/34205510_78_q55.avif';
import image3 from '../assets/images/BestCategories/34205615_105_q55.avif';
import image4 from '../assets/images/BestCategories/34205723_78_q55.avif';
import image5 from '../assets/images/BestCategories/34205769_78_q55.avif';
import image6 from '../assets/images/BestCategories/34205839_78_q55.avif';
import image7 from '../assets/images/BestCategories/34205885_78_q55.avif';
import big_image from '../assets/images/BestCategories/33987427_643_q55.avif';
import {Link} from "react-router-dom";

function BestCategories() {
    return (
        <div className="wrapper" style={{marginBottom: "50px"}}>
            <div className="container">
                <div className={styles.title}>
                    <p>Созданы для тебя.</p>
                    <h4>Лучшие устройства в одном магазине.</h4>
                </div>

                <div className={styles.section}>
                    <Link to="/iphone">
                        <div className={styles.item}>
                            <div>
                                <img src={image2}/>
                            </div>
                            <span>iPhone
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg>
                        </span>
                        </div>
                    </Link>

                    <Link to="/watch">
                        <div className={styles.item}>
                            <div>
                                <img src={image1}/>
                            </div>
                            <span>Watch
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg>
                        </span>
                        </div>
                    </Link>

                    <Link to="/mac">
                        <div className={styles.item}>
                            <div>
                                <img src={image3}/>
                            </div>
                            <span>Mac
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg>
                        </span>
                        </div>
                    </Link>

                    <Link to="/airpods">
                        <div className={styles.item}>
                            <div>
                                <img src={image4}/>
                            </div>
                            <span>AirPods
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg>
                        </span>
                        </div>
                    </Link>

                    <Link to="ipad">
                        <div className={styles.item}>
                            <div>
                                <img src={image5}/>
                            </div>
                            <span>iPad
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg>
                        </span>
                        </div>
                    </Link>

                    <Link to="/accessories">
                        <div className={styles.item}>
                            <div>
                                <img src={image6}/>
                            </div>
                            <span>Аксессуары
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg>
                        </span>
                        </div>
                    </Link>

                    <Link to="/dyson">
                        <div className={styles.item}>
                            <div>
                                <img src={image7}/>
                            </div>
                            <span>Dyson
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg>
                        </span>
                        </div>
                    </Link>

                </div>

                <Link to="/mac">
                    <div className={styles.section_big}>
                        <div className={styles.bg}>
                            <img src={big_image}/>
                        </div>
                        <h4>
                            MacBook Pro на процессоре M2 Pro и M2 Pro Max.
                        </h4>
                        <span>Купить <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default BestCategories;