import styles from '../scss/Credit.module.scss';
function CreditPay() {
    return (
        <div className={styles.creditpay}>
            <p>Для погашения кредита Вы можете выбрать любой удобный для Вас способ на всей территории РФ:</p>

            <ul>
                <li>В отделениях банка и дополнительных офисах (Банкоматы);</li>
                <li>В отделениях Почта России;</li>
                <li>Платёжная система «ЭЛЕКСНЕТ». При оплате взимается комиссия. Уточните размер комиссии заранее;</li>
                <li>Платёжная сеть «РАПИДА». При оплате взимается комиссия. Уточните размер комиссии заранее;</li>
                <li>Платёжная сеть «QIWI». При оплате взимается комиссия. Уточните размер комиссии заранее.</li>
                <li>Межбанковский перевод. Сумму очередного платежа по кредиту можно просто перевести. Для этого надо обратиться в любой ближайший банк и оформить обычный банковский перевод. Размер комиссии следует уточнить перед осуществлением перевода;</li>
                <li>Бухгалтерия организации, в которой работает клиент. Перечислять платежи по кредиту может бухгалтерия компании, в которой работает заемщик. Для этого достаточно заполнить и передать в бухгалтерию заявление на ежемесячное перечисление денежных средств из суммы заработной платы. Платежи будут осуществляться каждый месяц, в определенный день. От работника потребуется только контролировать этот процесс;</li>
                <li>С помощью Интернет-Банка;</li>
            </ul>

            <p>У наших банков-партнёров отсутствует комиссия за досрочное погашение кредита.</p>
            <p>В случае возврата товара мы вернем наличные в сумме первоначального взноса (при наличии такового), а сумму, взятую в кредит, перечислит на ваш счет обслуживания кредита.</p>
            <p>Всю информацию по кредиту вы сможете уточнить по номеру телефона указанному в Кредитном договоре.</p>

        </div>
    );
}

export default CreditPay