import {Navigate} from 'react-router-dom';
import {useAuth} from "../hooks/use-auth";
import {useDispatch} from "react-redux";
import {removeUser} from "../redux/slices/userSlice";

import { getDatabase, ref, set, child, get, push, update } from "firebase/database";

import styles from "../scss/Dashboard.module.scss";
import {data} from "../products";
import React from "react";
import moment from 'moment';

function ReviewItem({ item, handleCommentChange, handleSendUpdate, isExpanded, toggleExpand }) {
    return (
        <div className={styles.item}>
            <div className={styles.header} onClick={toggleExpand} style={{display: "flex", flexDirection: "column", padding: "5px", cursor: "pointer"}}>
                <h5 style={{margin: 0}}>{item.name}<span> {item.time}</span></h5>
                <span>{isExpanded ? 'Скрыть' : 'Показать'}</span>
            </div>
            {isExpanded && (
                <div className={styles.content}>
                    {/*<h5>Достоинства</h5>*/}
                    {/*<p>*/}
                    {/*    {item.plus.split('\n').map((line, lineIndex) => (*/}
                    {/*        <React.Fragment key={lineIndex}>*/}
                    {/*            {line}*/}
                    {/*            <br />*/}
                    {/*        </React.Fragment>*/}
                    {/*    ))}*/}
                    {/*</p>*/}
                    {/*<h5>Недостатки</h5>*/}
                    {/*<p>*/}
                    {/*    {item.minus.split('\n').map((line, lineIndex) => (*/}
                    {/*        <React.Fragment key={lineIndex}>*/}
                    {/*            {line}*/}
                    {/*            <br />*/}
                    {/*        </React.Fragment>*/}
                    {/*    ))}*/}
                    {/*</p>*/}

                    <h5>Отзыв</h5>
                    <p>
                        {item.review.split('\n').map((line, lineIndex) => (
                            <React.Fragment key={lineIndex}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>

                    <h5>Комментарий магазина</h5>
                    <p>
            <textarea
                rows="10"
                cols="45"
                name="text"
                placeholder="Комментарий магазина"
                defaultValue={item.comment}
                onChange={handleCommentChange}
            ></textarea>
                    </p>

                    <button onClick={() => handleSendUpdate(item.id)}>Опубликовать отзыв</button>
                </div>
            )}
        </div>
    );
}

function Dashboard() {
    const dispatch = useDispatch();
    const {isAuth, email} = useAuth();

    const [selectedValue, setSelectedValue] = React.useState('1');
   // const [selectedText, setSelectedText] = React.useState('');
    const [selectedName, setSelectedName] = React.useState('');
    const [selectedPhone, setSelectedPhone] = React.useState('');
    // const [selectedPlus, setSelectedPlus] = React.useState('');
    // const [selectedMinus, setSelectedMinus] = React.useState('');
    const [selectedReview, setSelectedReview] = React.useState('');
    const [selectedData, setSelectedData] = React.useState('');
    const [selectedActive, setSelectedActive] = React.useState('0');
    const [selectedComment, setSelectedComment] = React.useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        console.log(event.target.value);
    };

    const handleChangeActive = (event) => {
        setSelectedActive(event.target.value);
        console.log(event.target.value);
    };

    const handleChangeReview = (event) => {
        setSelectedReview(event.target.value);
    };
    // const handleChangeMinus = (event) => {
    //     setSelectedMinus(event.target.value);
    // };
    // const handleChangePlus = (event) => {
    //     setSelectedPlus(event.target.value);
    // };
    const handleChangeComment = (event) => {
        setSelectedComment(event.target.value);
    };

    const handleChangeName = (event) => {
        setSelectedName(event.target.value);
    };

    const handleChangeData= (event) => {
        setSelectedData(event.target.value);
    };


    const writeUserData = (selectedValue, selectedName, selectedPhone, selectedReview, selectedData, selectedActive, selectedComment) => {
        const db = getDatabase();

        console.log(selectedValue + "SSSSS");
        const postListRef = ref(db, 'reviews');
        const newPostRef = push(postListRef);
        set(newPostRef, {
            productid: selectedValue,
            name: selectedName,
            phone: selectedPhone,
            // plus: selectedPlus,
            // minus: selectedMinus,
            review: selectedReview,
            // text: selectedText,
            time: selectedData,
            active: Number(selectedActive),
            comment: selectedComment,
        });
    }

    const SendData = () => {
        if(selectedValue || selectedName || selectedPhone ||  selectedReview || selectedData || selectedActive || selectedComment) {
            writeUserData(selectedValue, selectedName, selectedPhone, selectedReview, selectedData, selectedActive, selectedComment);
           alert("Отзыв успешно занесен в базу")
        }else{
            alert("Заполните все данные")
        }
    }



    const dbRef = ref(getDatabase());
    const [isData, setData] = React.useState([]);

    const [isMounted, setMounted] = React.useState(true);

    React.useEffect(() => {
        //if(data[params.reviewId]) {
        if(isMounted) {
            get(child(dbRef, 'reviews')).then((snapshot) => {
                if (snapshot.exists()) {

                    const items = snapshot.val();
                    const obj = Object.keys(items).map((key) => {
                        return { id: key, ...items[key] };
                    });

                    setData(obj);


                    setMounted(false);

                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }
        console.log(isData);
        //}
    }, [isData]);


    const [comment, setComment] = React.useState('');

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    }

    // const SendUpdate = (comment) => {
    //     console.log(comment);
    // }

    const updateUserData = (id, comment) => {
        const db = getDatabase();
        const postRef = ref(db, `reviews/${id}`);

        update(postRef, {
            comment: comment,
            active: 1,
        });

        alert("Успешно обновлен!");
    };

    const handleSendUpdate = (id) => {
        //SendUpdate(comment);
        console.log(comment);
        console.log(id);

        updateUserData(id, comment);

    }

    const [isExpandedArray, setIsExpandedArray] = React.useState(isData.map(() => false));

    const toggleExpand = (index) => {
        setIsExpandedArray((prevExpandedArray) => {
            const newExpandedArray = [...prevExpandedArray];
            newExpandedArray[index] = !prevExpandedArray[index];
            return newExpandedArray;
        });
    };

    return isAuth ? (
        <div className="wrapper">
            <div className="container">
                <div className={styles.section}>
                    <div className={styles.selection}>
                        <select value={selectedValue} onChange={handleChange}>
                            <option disabled>Выберите продукт</option>

                            {
                                data.map((item,index) => {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.name}
                                        </option>
                                    );
                                })
                            }

                        </select>

                        <div className={styles.input}>
                            <input type="text" placeholder="Введите имя" value={selectedName} onChange={handleChangeName}/>
                        </div>

                        <div className={styles.input}>
                            <input type="text" placeholder="DD.MM.YYYY (Пример: 01.03.2023)" value={selectedData} onChange={handleChangeData}/>
                        </div>

                        <div className={styles.text}>
                            <textarea rows="10" cols="45" name="text" placeholder="Напишите отзыв" onChange={handleChangeReview} value={selectedReview}></textarea>
                        </div>

                        {/*<div className={styles.text}>*/}
                        {/*    <textarea rows="10" cols="45" name="text" placeholder="Напишите достоинства" onChange={handleChangePlus} value={selectedPlus}></textarea>*/}
                        {/*</div>*/}

                        {/*<div className={styles.text}>*/}
                        {/*    <textarea rows="10" cols="45" name="text" placeholder="Напишите недостатки" onChange={handleChangeMinus} value={selectedMinus}></textarea>*/}
                        {/*</div>*/}

                        <div className={styles.text}>
                            <textarea rows="10" cols="45" name="text" placeholder="Комментарий магазина" onChange={handleChangeComment} value={selectedComment}></textarea>
                        </div>

                        <select value={selectedActive} onChange={handleChangeActive}>
                            <option disabled>Проверка модерации</option>
                            <option value="1">Опубликовать</option>
                            <option value="0">Не публиковать (отложить)</option>
                        </select>

                    </div>
                    <div className={styles.buttons}>
                        <button onClick={SendData}>Создать отзыв</button>
                        <button onClick={() => dispatch(removeUser())}>Завершить сессию {email}</button>
                        <div className={styles.items}>
                            {isData.filter((item) => item.active === 0).map((item, index) => (
                                <ReviewItem
                                    key={index}
                                    item={item}
                                    handleCommentChange={handleCommentChange}
                                    handleSendUpdate={handleSendUpdate}
                                    isExpanded={isExpandedArray[index]}
                                    toggleExpand={() => toggleExpand(index)}
                                />
                            ))}
                        </div>
                    </div>


                </div>
            </div>
        </div>

    ) : (
       <Navigate to="/login"/>
    );
}

export default Dashboard;
