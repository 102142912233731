import React from "react";
import axios from 'axios';
import styles from "../scss/Order.module.scss";
import {openOrder, removeItem, clearItems} from "../redux/slices/orderSlice";
import {useDispatch, useSelector} from "react-redux";
import OrderProduct from "./OrderProduct";
import {setDelivery} from "../redux/slices/orderSlice";
import * as yup from "yup";
import {CSSTransition} from "react-transition-group";

function Order() {

    const isMounted = React.useRef(false);

    const ref = React.useRef();
    const isVisible = useSelector((state) => state.order.value);
    //const setDelivery = useSelector((state) => state.order.deliveryCost);
    const {items, totalPrice} = useSelector((state) => state.order);
    const dispatch = useDispatch();


    const CloseModal = () => {
        ref.current.style.display = "none";
        dispatch(openOrder(!isVisible));
        //console.log(items);
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "initial";
        }
    }, []);

    React.useEffect(() => {
        if(isMounted.current) {
            const json = JSON.stringify(items);
            console.log(json);
            localStorage.setItem('cart', json);
        }

        isMounted.current = true;
    }, [items])

    const refDeliveryItem = React.useRef(0);



    React.useEffect(() => {
        const obj = refDelivery.current.children[0];
        console.log(obj);
        obj.style.border = "1px solid #000";
        const cost = obj.getAttribute('cost');
        setCost(obj.getAttribute("cost"));
        dispatch(setDelivery(cost));
    }, []);

    const refDelivery = React.useRef();

    const [isCost, setCost] = React.useState(0);

    const handleClickDelivery = (e) => {
        const obj = refDelivery.current.childNodes;
        const target = e.target.closest('button').style;
        for(let i = 0; i < obj.length; i++) {
            obj[i].style.border = "1px solid #bababa"
        }

        target.border = "1px solid #000";


        setCost(e.target.closest('button').getAttribute("cost"));
        dispatch(setDelivery(e.target.closest('button').getAttribute("cost")));
    }

    const refChooseContact = React.useRef('Перезвонить');
    const refDropdown = React.useRef(false);

    const [isContact, openContact] = React.useState(false);

    const handleClickContact = () => {
        openContact(!isContact);
    }

    const handleClickChoose = (e) => {
        refChooseContact.current.innerText = e.target.innerText;
        openContact(!isContact);
    }

    const delivery = [
        {name: "Почта России", cost: 300},
        {name: "СДЭК / Деловые линии", cost: 700},
        {name: "ПЭК", cost: 400},
        {name: "Express СДЭК / Деловые линии", cost: 1200}
    ]

    console.log(totalPrice + " - " + isCost);

    const [pdfFile, setPdfFile] = React.useState(null);

    const sendEmail = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://one-iphonestore.ru/api/send-email', { pdfFile });
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const [formData, setFormData] = React.useState({
        name: '',
        phone: '',
        email: '',
    });


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({...formData, [name]: value});
    }

    const validationSchema = yup.object().shape({
        name: yup.string().required('Обязательно для заполнения'),
        phone: yup.string().required('Обязательно для заполнения'),
        email: yup.string().required('Обязательно для заполнения'),
    });

    const [formErrors, setFormErrors] = React.useState({});

    const validate = () => {
        validationSchema.validate(formData, { abortEarly: false })
            .then(() => {
                setFormErrors({});
            })
            .catch((error) => {
                const errors = error.inner.reduce((acc, cur) => {
                    return { ...acc, [cur.path]: cur.message };
                }, {});
                setFormErrors(errors);
            });
    }

    const handleSubmit = (event) => {
       // setFormData({...formData, storage: items});

        console.log(formData.storage);

        event.preventDefault();
        validate();
        if (Object.keys(formErrors).length === 0) {

            axios.post('http://one-iphonestore.ru/api/send-order', {formData, items}, {timeout: 3000})
          //  axios.post('http://localhost:4000/api/send-order', {formData, items}, {timeout: 3000})
                .then((response) => {
                    console.log(response);
                    console.log("ok");
                })
                .catch((error) => {
                    console.log(error);
                });

            setText('Отправка ...');
            setTimeout(() => {
                setText('Отправлено!');
                formData.name = '';
                formData.phone = '';
                formData.email = '';
                //

            }, 2000);

            setTimeout(() => {
                setText('Отправить');
            }, 10000);
        }
    }

    const [sendText, setText] = React.useState('Отправить');

    return (
        <div ref={ref} className={styles.popupFade}>
            <div className={styles.popup}>

                <div className={styles.content}>
                    <p onClick={() => CloseModal()} className={styles.closeCard}><svg viewBox="0 0 10 10"><path d="M5 4L1 0L0 1L4 5L0 9L1 10L5 6L9 10L10 9L6 5L10 1L9 0L5 4Z"></path></svg></p>
                    <div className={styles.data}>
                        <h4>Оформить заказ.</h4>
                        <form className={styles.form} onSubmit={handleSubmit}>
                            <div className={styles.form_group}>
                                <label htmlFor="name">ФИО *</label>
                                <input type="text" name="name" className={styles.form_control} value={formData.name} onChange={handleInputChange} onBlur={validate}/>
                                <CSSTransition in={formErrors.name != null} timeout={300} classNames="error-message" unmountOnExit>
                                    <div className="error-message">{formErrors.name}</div>
                                </CSSTransition>
                            </div>
                            <div className={styles.form_group}>
                                <label htmlFor="phone">Телефон *</label>
                                <input type="text" name="phone" className={styles.form_control} value={formData.phone} onChange={handleInputChange} onBlur={validate}/>
                                <CSSTransition in={formErrors.phone != null} timeout={300} classNames="error-message" unmountOnExit>
                                    <div className="error-message">{formErrors.phone}</div>
                                </CSSTransition>
                            </div>


                            <div className={styles.form_group}>
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" className={styles.form_control} value={formData.email} onChange={handleInputChange} onBlur={validate}/>
                                <CSSTransition in={formErrors.email != null} timeout={300} classNames="error-message" unmountOnExit>
                                    <div className="error-message">{formErrors.email}</div>
                                </CSSTransition>
                            </div>

                            <div className={styles.form_group}>
                                <div className={styles.form_contact}>
                                    <p>Как с вами связаться?</p>
                                    <div className={styles.choosecontact}>
                                        <div className={styles.input} onClick={() => handleClickContact()}>
                                      <span style={{display: isContact ? 'none' : ''}}><strong ref={refChooseContact}>Перезвонить</strong><svg viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" d="M 1.4 6.99441e-15L 0 1.34043L 5 6L 10 1.34043L 8.6 0L 5 3.34998L 1.4 6.99441e-15Z"></path>
                                        </svg></span>

                                            {
                                                isContact && (
                                                    <div ref={refDropdown} className={styles.dropdown}>
                                                        <ul onClick={(e) => handleClickChoose(e)}>
                                                            <li>Перезвонить</li>
                                                            <li>Написать в WhatsApp</li>
                                                        </ul>
                                                    </div>
                                                )
                                            }

                                        </div>


                                    </div>

                                </div>
                            </div>

                            <div className={styles.form_group}>
                                <p>Доставка</p>
                                <div ref={refDelivery} className={styles.delivery} onClick={(e) => handleClickDelivery(e)}>

                                    {delivery.map((item, index) =>
                                        (
                                            <button ref={refDeliveryItem} key={index} type="button" className={styles.item} {...item}>
                                                <span>{item.name}<strong>–</strong><h5>{item.cost} р.</h5></span>
                                                {/*<p>Отправим заказ курьерской службой СДЭК. Быстрый и надежный партнёр. </p>*/}
                                            </button>
                                        )
                                    )}
                                </div>
                            </div>


                            <div className={styles.button}>
                                <button className={styles.send_button} type="submit">
                                    <span>{sendText} <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-3 -3 22 22" id="icon"><path d="M13.982 6.993c0-.01-.008-.02-.008-.028a.462.462 0 0 0-.104-.253c-.016-.02-.01-.05-.03-.068-.015-.016-.038-.01-.056-.023-.008-.005-.012-.015-.02-.02L8.33 1.657a.49.49 0 0 0-.69 0c-.188.19-.188.5 0 .688L12.214 6.5H.513a.5.5 0 0 0 0 .998h11.7L7.64 11.653a.488.488 0 0 0 0 .688c.19.19.5.19.69 0l5.462-4.965c.008-.006.018-.005.025-.01.006-.007.018-.004.023-.01s.004-.02.01-.025a.492.492 0 0 0 .127-.307c0-.006.004-.01.004-.018l.003-.007v-.005z"/></svg></span>
                                </button>
                            </div>

                        </form>
                    </div>
                    <div className={styles.items}>
                        <h4>Корзина.</h4>

                        <div className={styles.products}>

                            {items.map((obj, index) => (
                                <OrderProduct key={index} {...obj}/>
                            ))}

                        </div>

                        <div className={styles.subtotal}>
                            <h4>Сумма</h4>
                            <p>{totalPrice} р.</p>
                        </div>
                        <div className={styles.shipping}>
                            <h4>Доставка</h4>
                            <p>{isCost} р.</p>
                        </div>
                        <div className={styles.hr}></div>
                        <div className={styles.summary}>
                            <h4>Итого</h4>
                            <p>{Number(totalPrice) + Number(isCost)} р.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Order