import { createSlice } from '@reduxjs/toolkit'
//import type { PayloadAction } from '@reduxjs/toolkit'


const initialState = {
    value: false,
    dropdown: false,
    catalog: false,
    contact: false,
    review: false,
    emailform: false,
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        open: (state, action) => {
            state.value = true
        },
        close: (state, action) => {
            state.value = false
        },
        openList: (state, {payload}) => {
            state.dropdown = payload
        },
        openCatalog: (state, {payload}) => {
            state.catalog = payload
        },
        openContactForm: (state, {payload}) => {
            state.contact = payload
        },
        openReviewForm: (state, {payload}) => {
            state.review = payload
        },
        openEmailForm: (state, {payload}) => {
            state.emailform = payload
        },
    },
})

export const { open, close, openList, openCatalog, openContactForm, openReviewForm, openEmailForm} = menuSlice.actions

export default menuSlice.reducer