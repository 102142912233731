import React from "react";
import styles from '../scss/LoveAccessories.module.scss';

function LoveAccessories() {
    return (
        <div className="wrapper">
            <div className="container">
                <div className={styles.section}>
                    <div className={styles.text}>
                        <div>
                            <h4>Любимые аксессуары</h4>
                            <p>Только самое лучшее и полезное.</p>
                        </div>
                    </div>
                    <div className={styles.image}>
                        {/*<img src={image}/>*/}
                        <div className={styles.bg}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoveAccessories;