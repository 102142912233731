import LoveAccessories2 from "../components/LoveAccessories2";
import BestCategories from "../components/BestCategories";
import About from "../components/About";
import Feedback from "../components/Feedback";
import BenefitsHome from "../components/BenefitsHome";

function Home() {
    return (
        <>
            <LoveAccessories2/>
            <BestCategories/>
            <About/>
            <BenefitsHome/>
            <Feedback/>
        </>
    );
}

export default Home;
