import React from "react";
import axios from "axios";

function Send() {
    const handleClick = () => {
        axios
            .post("http://37.143.9.189:4000/api/send-email")
            .then((response) => {
               console.log(response.data);
            });
    }

    return (
        <div style={{paddingTop: "120px"}}>
            <button onClick={() => handleClick()}>Отправить</button>
        </div>
    );
}

export default Send;